import { HiOutlineChatAlt } from '@react-icons/all-files/hi/HiOutlineChatAlt';
import { defineType } from '@sanity/types';

import { BlockContentType } from '../block/_types';
import { ItoInnerCriticModeType } from '../ItoInnerCriticMode';
import { FlowBaseStepType } from './baseTypes';

export type FlowItoTextStepType = FlowBaseStepType & {
	_type: 'FlowItoTextStep';
	id: string;
	inputFieldLabel: string;
	mode: ItoInnerCriticModeType;
	loadingResponseText: string;
	footerContent?: BlockContentType;
	answerQuestionButtonTitle: string;
	missingInputErrorMessage: string;
};

export default defineType({
	name: 'FlowItoTextStep',
	type: 'document',
	icon: HiOutlineChatAlt,
	fields: [
		{
			name: 'inputFieldLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'mode',
			type: 'reference',
			to: [{ type: 'ItoInnerCriticMode' }],
			validation: (r) => r.required(),
		},
		{
			name: 'loadingResponseText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'reference',
			to: [{ type: 'BlockContentWrapper' }],
		},
		{
			name: 'answerQuestionButtonTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'missingInputErrorMessage',
			type: 'string',
			validation: (r) => r.required(),
			description:
				'This is the error message that will be shown if the user does not enter any text in the input field.',
		},
	],
});
