import { defineType } from '@sanity/types';
import { UITheme } from '@wearemojo/ui-constants';

import { Document } from '../_types';

type ThemeSetting = UITheme | 'system';

export type FragmentAccountPreferencesType = Document & {
	_type: 'FragmentAccountPreferences';
	themeSettingTitle: string;
	themeSettingOptions: {
		key: ThemeSetting;
		name: string;
		description?: string;
	}[];
};

export default defineType({
	name: 'FragmentAccountPreferences',
	type: 'document',
	fields: [
		{
			name: 'themeSettingTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'themeSettingOptions',
			type: 'array',
			validation: (r) => r.required().min(1).custom(uniqueKeysValidation),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => r.required(),
							options: {
								list: ['light', 'dark', 'system'],
							},
						},
						{
							name: 'name',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'description',
							type: 'string',
						},
					],
					preview: {
						select: {
							title: 'name',
							subtitle: 'key',
						},
					},
				},
			],
		},
	],
});

const uniqueKeysValidation = (
	val?: FragmentAccountPreferencesType['themeSettingOptions'],
) => {
	if (!val) return true;
	if (val.length !== new Set(val.map((item) => item.key)).size) {
		return 'Duplicate keys found in choice options';
	}
	return true;
};
