import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentType } from '../block/_types';

export type FragmentRedirectToAppType = Document & {
	_type: 'FragmentRedirectToApp';
	buttonContent: BlockContentType;
	buttonText: string;
	qrContent: BlockContentType;
};

export default defineType({
	name: 'FragmentRedirectToApp',
	type: 'document',
	fields: [
		{
			name: 'buttonContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'buttonText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'qrContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'buttonText',
		},
	},
});
