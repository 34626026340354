import { HiOutlineDocumentDuplicate } from '@react-icons/all-files/hi/HiOutlineDocumentDuplicate';
import { defineType } from '@sanity/types';

import { arrayOfTypesBoolean } from '../expressions/_types';
import { renderExpression } from '../expressions/_utils';
import { FlowGroupType, flowStepTypes } from './_types';

type InternalType = FlowGroupType & {
	addCondition?: boolean;
};

type CaseType = NonNullable<FlowGroupType['cases']>[number];

export default defineType({
	name: 'FlowGroup',
	type: 'document',
	icon: HiOutlineDocumentDuplicate,
	fields: [
		{
			name: 'addCondition',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'cases',
			type: 'array',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'condition',
							type: 'array',
							validation: (r) => r.min(1),
							of: arrayOfTypesBoolean,
							description:
								'Condition to be evaluated to determine if items should be shown',
						},
						{
							name: 'items',
							type: 'array',
							of: [
								{
									type: 'reference',
									to: [
										{ type: 'FlowGroup' },
										...flowStepTypes.map((type) => ({ type })),
									],
								},
							],
							description: 'Steps/groups to be shown if the condition is true',
						},
					],
					preview: {
						select: {
							condition: 'condition',
							items: 'items',
						},
						prepare: ({ condition, items }: Partial<CaseType>) => ({
							title: `${items?.length ?? 0} items`,
							subtitle: renderExpression({
								_type: 'ExpressionLogicalAnd',
								operands: condition ?? [],
							}),
						}),
					},
				},
			],
			hidden: ({ parent }: { parent: InternalType }) => !parent?.addCondition,
		},
		{
			name: 'defaultItems',
			type: 'array',
			of: [
				{
					type: 'reference',
					to: [
						{ type: 'FlowGroup' },
						...flowStepTypes.map((type) => ({ type })),
					],
				},
			],
			description: 'Steps/groups to be shown if the condition is false',
		},
	],
	validation: (r) =>
		r.custom<Required<InternalType>>((value) => {
			switch (true) {
				case Boolean(value?.addCondition && value?.cases?.length):
				case Boolean(!value?.addCondition && !value?.cases?.length):
					return true;
				default:
					return 'Invalid cases';
			}
		}),
	preview: {
		select: {
			cases: 'cases',
			defaultItems: 'defaultItems',
		},
		prepare: ({ cases, defaultItems }: Partial<FlowGroupType>) => {
			return {
				title: cases?.length
					? `${cases.length + 1}-way conditional group`
					: `${defaultItems?.length ?? 0}-item group`,
			};
		},
	},
});
