import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBasePageType } from './baseTypes';

const swScoreOnboardingStepIcons = ['trophy', 'scoreProgress'] as const;

type SWScoreOnboardingStepIconType =
	(typeof swScoreOnboardingStepIcons)[number];

export type FlowSWScoreOnboardingStepType = FlowBasePageType & {
	_type: 'FlowSWScoreOnboardingStep';
	imageIcon?: SWScoreOnboardingStepIconType;
	tag: string;
	subtitle?: InlineContentType;
	content?: BlockContentType;
};

export default defineType({
	name: 'FlowSWScoreOnboardingStep',
	type: 'document',
	fields: [
		{
			name: 'imageIcon',
			type: 'string',
			options: {
				list: swScoreOnboardingStepIcons.map((item) => ({
					value: item,
					title: item.substring(0, 1).toUpperCase() + item.substring(1),
				})),
			},
		},
		{
			name: 'tag',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'subtitle',
			type: 'InlineContent',
		},
		{
			name: 'content',
			type: 'BlockContent',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
