import { HiOutlineDocumentDuplicate } from '@react-icons/all-files/hi/HiOutlineDocumentDuplicate';
import { defineType } from '@sanity/types';

import { ProgramActivityType } from './ProgramActivity';

export type ProgramActivityGroupType = {
	_type: 'ProgramActivityGroup';
	items: ProgramActivityType[];
};

export default defineType({
	name: 'ProgramActivityGroup',
	type: 'object',
	icon: HiOutlineDocumentDuplicate,
	fields: [
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'items',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'reference',
					to: [{ type: 'ProgramActivity' }],
				},
			],
		},
	],
	preview: {
		select: {
			name: 'name',
			items: 'items',
		},
		prepare: (selection) => {
			const { name, items } = selection;
			const { length } = items ?? [];
			return {
				title: name,
				subtitle: `${length} activit${length === 1 ? 'y' : 'ies'}`,
			};
		},
	},
});
