import { HiAcademicCap } from '@react-icons/all-files/hi/HiAcademicCap';
import { Asset, defineType } from '@sanity/types';

import { InlineContentType } from './InlineContent';

export type TileWithExplanationListType = {
	_type: 'TileWithExplanation';
	items: {
		image: { asset: Asset };
		imageAlt: string;
		title: string;
		content: InlineContentType;
	}[];
};

export default defineType({
	name: 'TileWithExplanationList',
	type: 'object',
	icon: HiAcademicCap,
	fields: [
		{
			name: 'items',
			type: 'array',
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{ name: 'image', type: 'image', validation: (r) => r.required() },
						{
							name: 'imageAlt',
							type: 'string',
							validation: (r) => r.required(),
						},
						{ name: 'title', type: 'string' },
						{
							name: 'content',
							type: 'InlineContent',
							validation: (r) => r.required(),
						},
					],
					preview: {
						select: {
							title: 'title',
							content: 'content',
						},
						prepare: ({ title, content }) => ({
							title: `${title}`,
							subtitle: `${content}`,
						}),
					},
				},
			],
		},
	],
	preview: {
		select: {
			items: 'items',
		},
		prepare(selection) {
			const { items } = selection as Pick<TileWithExplanationListType, 'items'>;
			return {
				title: `TileWithExplanation - ${items.length} items`,
				subtitle: items
					.map(({ title, content }) => `${title} - ${content}`)
					.join(', '),
			};
		},
	},
});
