import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type CurrentSubscriptionFields = {
	title: string;
	content: InlineContentType;
};

export type FragmentCurrentSubscriptionType = Document & {
	_type: 'FragmentCurrentSubscription';
	iapSubscription: CurrentSubscriptionFields;
	promotionalEntitlement: CurrentSubscriptionFields;
	subscriptionRenewal: CurrentSubscriptionFields;
	subscriptionEnding: CurrentSubscriptionFields;
	trialConversion: CurrentSubscriptionFields;
	trialEnding: CurrentSubscriptionFields;
};

const fragmentFields = [
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'content',
		type: 'InlineContent',
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'FragmentCurrentSubscription',
	type: 'document',
	fields: [
		{
			name: 'iapSubscription',
			type: 'object',
			validation: (r) => r.required(),
			fields: fragmentFields,
		},
		{
			name: 'promotionalEntitlement',
			type: 'object',
			validation: (r) => r.required(),
			fields: fragmentFields,
		},
		{
			name: 'subscriptionRenewal',
			type: 'object',
			validation: (r) => r.required(),
			fields: fragmentFields,
		},
		{
			name: 'subscriptionEnding',
			type: 'object',
			validation: (r) => r.required(),
			fields: fragmentFields,
		},
		{
			name: 'trialConversion',
			type: 'object',
			validation: (r) => r.required(),
			fields: fragmentFields,
		},
		{
			name: 'trialEnding',
			type: 'object',
			validation: (r) => r.required(),
			fields: fragmentFields,
		},
	],
});
