import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { FlowType } from '../flows/Flow';

export type FragmentFlowsType = Document & {
	_type: 'FragmentFlows';
	registrationPrefaceFlow: FlowType;
	checkoutPrefaceFlow: FlowType;
	welcomeFlow: FlowType;
};

export default defineType({
	name: 'FragmentFlows',
	type: 'document',
	fields: [
		{
			name: 'registrationPrefaceFlow',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Flow' }],
		},
		{
			name: 'checkoutPrefaceFlow',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Flow' }],
		},
		{
			name: 'welcomeFlow',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Flow' }],
		},
	],
});
