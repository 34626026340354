import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';

export type FragmentGroupSessionsType = Document & {
	_type: 'FragmentGroupSessions';
	calendlyUrl: string;
	content: BlockContentType;
};

export default defineType({
	name: 'FragmentGroupSessions',
	type: 'document',
	fields: [
		{
			name: 'calendlyUrl',
			type: 'url',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
