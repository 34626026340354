import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';

export type FragmentCheckoutFooterType = Document & {
	_type: 'FragmentCheckoutFooter';
	title?: string;
	content?: BlockContentType;
	noTrialTitle?: string;
	noTrialContent?: BlockContentType;
};

export default defineType({
	name: 'FragmentCheckoutFooter',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
		},
		{
			name: 'content',
			type: 'BlockContent',
		},
		{
			name: 'noTrialTitle',
			type: 'string',
		},
		{
			name: 'noTrialContent',
			type: 'BlockContent',
		},
	],
	preview: previewBlockContent(),
});
