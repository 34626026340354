import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentCommunityHomeType = Document & {
	_type: 'FragmentCommunityHome';
	title: string;
	onboardingStep: {
		content: BlockContentType;
		ctaText: string;
	}[];
	topicsFilter: {
		title: string;
		buttonTitle: string;
	};
	sortByFilter: {
		title: string;
		buttonTitle: string;
		options: {
			id: string;
			name: string;
		}[];
	};
	nextConnectSessionCtaText: InlineContentType;
};

const filterSheet = [
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'buttonTitle',
		type: 'string',
		validation: (r) => r.required(),
	}),
];

const sortByFilterKeys = [
	'latest_updated_first',
	'newest_first',
	'oldest_first',
	'most_liked',
	'most_replies',
	'hot',
] as const;

const sortByFilterOptions = [
	defineField({
		name: 'options',
		type: 'array',
		of: [
			{
				type: 'object',
				fields: [
					defineField({
						name: 'id',
						type: 'string',
						validation: (Rule) =>
							Rule.required().custom((keyValue) => {
								if (!keyValue) return 'Id is required';
								return sortByFilterKeys.includes(keyValue as any)
									? true
									: `Must be one of ${sortByFilterKeys.join(', ')} but got ${keyValue}`;
							}),
					}),
					defineField({
						name: 'name',
						type: 'string',
						validation: (r) => r.required(),
					}),
				],
			},
		],
		validation: (r) => r.required().min(1),
	}),
];

export default defineType({
	name: 'FragmentCommunityHome',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'onboardingStep',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'content',
							type: 'BlockContent',
							validation: (r) => r.required(),
						},
						{
							name: 'ctaText',
							type: 'string',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'topicsFilter',
			type: 'object',
			validation: (r) => r.required(),
			fields: filterSheet,
		},
		{
			name: 'sortByFilter',
			type: 'object',
			validation: (r) => r.required(),
			fields: [...filterSheet, ...sortByFilterOptions],
		},
		{
			name: 'nextConnectSessionCtaText',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
