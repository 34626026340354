import { defineType } from '@sanity/types';

import {
	arrayOfTypesArray,
	arrayOfTypesString,
	ExpressionArrayContainsStringType,
} from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionArrayContainsString',
	title: 'List Contains Text',
	type: 'object',
	fields: [
		{
			name: 'arrayOperand',
			title: 'List',
			description: 'List currently refers to type of Poll',
			type: 'array',
			validation: (r) => r.required().length(1),
			of: arrayOfTypesArray,
		},
		{
			name: 'stringOperand',
			title: 'Text',
			type: 'array',
			validation: (r) => r.required().length(1),
			of: arrayOfTypesString,
		},
	],
	preview: {
		select: {
			_type: '_type',
			arrayOperand: 'arrayOperand',
			stringOperand: 'stringOperand',
		},
		prepare: (expression: ExpressionArrayContainsStringType) => ({
			title: renderExpression(expression),
		}),
	},
});
