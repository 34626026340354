import { defineType } from '@sanity/types';

import { validateSnakeCase } from './_utils';
import { InlineContentType } from './block/InlineContent';

export type CancelationQuestionOptionType = {
	key: string;
	name: string;
	subQuestion?: CancelationQuestionType;
};

export type CancelationQuestionType = {
	_type: 'CancelationQuestion';
	headline?: InlineContentType;
	title: string;
	subtitle?: InlineContentType;
	options?: CancelationQuestionOptionType[];
};

export default defineType({
	name: 'CancelationQuestion',
	type: 'object',
	fields: [
		{
			name: 'headline',
			type: 'InlineContent',
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'subtitle',
			type: 'InlineContent',
		},
		{
			name: 'options',
			type: 'array',
			of: [
				{
					type: 'document',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required(),
						},
						{
							name: 'name',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'subQuestion',
							type: 'CancelationQuestion',
						},
					],
				},
			],
		},
	],
});
