import { defineType } from '@sanity/types';

import { validateSnakeCase } from '../_utils';

export type ProgramActivityFilterSingleMetadataType = {
	_type: 'ProgramActivityFilterSingleMetadata';
	key: string;
	value: string;
};

export default defineType({
	name: 'ProgramActivityFilterSingleMetadata',
	type: 'object',
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required().min(1),
		},
		{
			name: 'value',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required().min(1),
		},
	],
	preview: {
		select: {
			key: 'key',
			value: 'value',
		},
		prepare: ({ key, value }) => ({
			title: `${key} = ${value}`,
		}),
	},
});
