import { HiOutlineBookOpen } from '@react-icons/all-files/hi/HiOutlineBookOpen';
import { defineType } from '@sanity/types';

import { Document } from './_types';
import { SoundSeriesType } from './SoundSeries';

export type AudioPornType = Document & {
	_type: 'AudioPorn';
	description: string;
	series: Array<SoundSeriesType>;
};

export default defineType({
	name: 'AudioPorn',
	type: 'document',
	icon: HiOutlineBookOpen,
	fields: [
		{
			name: 'description',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'series',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					name: 'SoundSeries',
					type: 'reference',
					to: [{ type: 'SoundSeries' }],
				},
			],
		},
	],
	preview: {
		select: {
			series: 'series',
		},
		prepare({ series }: { series: SoundSeriesType[] }) {
			return {
				title: 'Audio porn',
				subtitle: `${series.length} series`,
			};
		},
	},
});
