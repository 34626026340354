import { defineType } from '@sanity/types';

import { ActionBaseFields, ActionBaseType } from './_utils';

export type ReloadAppActionType = ActionBaseType & {
	_type: 'reloadApp';
};

export default defineType({
	name: 'reloadApp',
	type: 'object',
	fields: ActionBaseFields({
		defaultTitle: 'Reload',
	}),
});
