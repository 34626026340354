/*
	This should line up with the default Link mark annotation in Sanity, which
	doesn't seem to otherwise be exported anywhere

	We replicate it here to use alongside custom annotation definitions, as when we
	provide a custom definition the defaults (i.e. Link) disappear from the editor

	e.g. { type: 'block', marks: { annotations: [ContentVariable, Link] } }
*/

import { defineField } from '@sanity/types';

const Link = defineField({
	title: 'Link',
	name: 'link',
	type: 'object',
	fields: [
		{
			title: 'URL',
			name: 'href',
			type: 'url',
		},
	],
});

export default Link;
