import { HiBadgeCheck } from '@react-icons/all-files/hi/HiBadgeCheck';
import { defineType } from '@sanity/types';

import { Image } from '../_types';

export type FlairType = {
	_type: 'Flair';
	heading: string;
	image: Image;
	icon?: 'tick';
};

export default defineType({
	name: 'Flair',
	type: 'object',
	icon: HiBadgeCheck,
	fields: [
		{
			name: 'heading',
			type: 'string',
		},
		{
			name: 'icon',
			type: 'string',
			options: {
				list: [{ title: 'Tick', value: 'tick' }],
			},
		},
		{
			name: 'image',
			type: 'image',
			validation: (r) => r.required(),
		},
	],
	preview: {
		prepare: () => ({
			title: 'Flair',
		}),
	},
});
