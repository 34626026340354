import { defineType } from '@sanity/types';

import { ExpressionConstantArrayType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionConstantArray',
	title: 'Static List',
	type: 'object',
	fields: [
		{
			name: 'value',
			title: 'List',
			type: 'array',
			of: [{ type: 'string' }],
		},
	],
	preview: {
		select: {
			_type: '_type',
			value: 'value',
		},
		prepare: (expression: ExpressionConstantArrayType) => ({
			title: renderExpression(expression),
		}),
	},
});
