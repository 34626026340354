import { FaTrophy } from '@react-icons/all-files/fa/FaTrophy';
import { defineType } from '@sanity/types';

import { ksuidIdField } from './_utils';
import { InlineContentType } from './block/InlineContent';
import {
	arrayOfTypesBoolean,
	ExpressionBooleanType,
} from './expressions/_types';
import { ProgramActivityFilterExactActivityType } from './program/ProgramActivityFilterExactActivity';
import { ProgramActivityFilterSingleMetadataType } from './program/ProgramActivityFilterSingleMetadata';
import { SQScorePillar } from './SQScorePillar';

export type ContributingChallengeActivitiesType =
	| ProgramActivityFilterExactActivityType
	| ProgramActivityFilterSingleMetadataType;

export type ChallengeType = {
	_type: 'Challenge';
	id: string;
	name: string;
	title: string;
	subtitle: string;
	description: InlineContentType;
	buttonCta: string;
	backHomeButtonCta: string;
	offeringCondition: ExpressionBooleanType[];
	completionsRequired: number;
	awardCoins: number;
	contributingActivities: ContributingChallengeActivitiesType[];
	challengeSelectorTitle: string;
	challengeSelectorDescription: string;
	infoCard: {
		title: InlineContentType;
		participants: InlineContentType;
		coinsContent: InlineContentType;
	};
	declineCtaText: string;
	acceptCtaText: string;
	pillar: SQScorePillar;
	progressPanel: {
		completedTitle: string;
		completedSubtitle: InlineContentType;
		madeProgressTitle: string;
		madeProgressSubtitle: InlineContentType;
		ctaTitle: string;
		challengeDescription: InlineContentType;
		chipContent: InlineContentType;
	};
};

export default defineType({
	name: 'Challenge',
	type: 'document',
	icon: FaTrophy,
	fields: [
		ksuidIdField('chal', true),
		{
			name: 'name',
			description:
				'The name of the challenge that appears in the challenge screen header (e.g., "Replace porn")',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'title',
			type: 'string',
			description:
				'The status text shown above the challenge name (e.g., "Active challenge")',
			validation: (r) => r.required(),
		},
		{
			name: 'subtitle',
			type: 'string',
			description:
				'A brief explanatory text that appears below the challenge name (e.g., "Replace porn with Mojo activities")',
			validation: (r) => r.required(),
		},
		{
			name: 'description',
			description:
				'A more detailed explanation of the challenge displayed on the challenge screen',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'buttonCta',
			type: 'string',
			description:
				'The call-to-action text displayed on the button that leads to the challenge selector screen',
			validation: (r) => r.required(),
		},
		{
			name: 'backHomeButtonCta',
			type: 'string',
			description:
				'The call-to-action text displayed on the button that leads back to the home screen (when there are no completed contributing activities)',
			validation: (r) => r.required(),
			initialValue: 'Back to home screen',
		},
		{
			name: 'offeringCondition',
			type: 'array',
			of: arrayOfTypesBoolean,
			validation: (r) => r.required().min(1),
			description:
				'Determines whether the user is eligible to participate in the challenge.',
		},
		{
			name: 'completionsRequired',
			description:
				'The number of activities the user must complete in order to complete the challenge.',
			type: 'number',
			validation: (r) => r.required().min(1),
			initialValue: 1,
		},
		{
			name: 'awardCoins',
			description:
				'The number of coins the user will receive for completing the challenge.',
			type: 'number',
			validation: (r) => r.required().min(1),
			initialValue: 1,
		},
		{
			name: 'contributingActivities',
			type: 'array',
			of: [
				{ type: 'ProgramActivityFilterExactActivity' },
				{ type: 'ProgramActivityFilterSingleMetadata' },
			],
			description: 'Which activities to consider (any match will count)',
			validation: (r) => r.required().min(1),
		},
		{
			name: 'challengeSelectorTitle',
			description:
				'The title displayed on the screen where users select activities for the challenge',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'challengeSelectorDescription',
			description: 'A brief description for the challenge selector screen',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'infoCard',
			type: 'object',
			description: 'Information displayed on the challenge info card',
			fields: [
				{
					name: 'title',
					type: 'InlineContent',
					description: 'Brief description of the challenge',
					validation: (r) => r.required(),
				},
				{
					name: 'participants',
					type: 'InlineContent',
					description:
						'Text describing the number of participants (e.g., "1,240 members")',
					validation: (r) => r.required(),
				},
				{
					name: 'coinsContent',
					type: 'InlineContent',
					description:
						'Text describing the coins to be earned (e.g., "earn 6 coins")',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'declineCtaText',
			type: 'string',
			description: 'CTA text displayed on the button to decline the challenge',
			validation: (r) => r.required(),
			initialValue: 'Decline',
		},
		{
			name: 'acceptCtaText',
			type: 'string',
			description: 'CTA text displayed on the button to accept the challenge',
			validation: (r) => r.required(),
			initialValue: 'Accept challenge',
		},
		{
			name: 'progressPanel',
			type: 'object',
			description: 'Progress panel for the challenge',
			fields: [
				{
					name: 'completedTitle',
					type: 'string',
					description: 'Title for the completed challenge',
					validation: (r) => r.required(),
					initialValue: 'Challenge complete!',
				},
				{
					name: 'completedSubtitle',
					type: 'InlineContent',
					description: 'Subtitle for the completed challenge',
					validation: (r) => r.required(),
				},
				{
					name: 'madeProgressTitle',
					type: 'string',
					description: 'Title for the made progress',
					validation: (r) => r.required(),
					initialValue: `You've made progress!`,
				},
				{
					name: 'madeProgressSubtitle',
					type: 'InlineContent',
					description: 'Subtitle for the made progress',
					validation: (r) => r.required(),
				},
				{
					name: 'ctaTitle',
					type: 'string',
					description: 'Title for the CTA',
					validation: (r) => r.required(),
					initialValue: 'Okay',
				},
				{
					name: 'challengeDescription',
					type: 'InlineContent',
					description:
						'Description for the challenge, you can use the challengeName and numberCompletions params to fill out the title',
					validation: (r) => r.required(),
				},
				{
					name: 'chipContent',
					type: 'InlineContent',
					description:
						'Chip content for the completed challenge (e.g., "+6 Mojo coins!")',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'pillar',
			type: 'SQScorePillar',
		},
	],
	preview: {
		select: {
			name: 'name',
		},
		prepare: ({ name }) => ({
			title: `${name} challenge`,
			media: FaTrophy,
		}),
	},
});
