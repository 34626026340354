import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { SWScoreThresholdType } from '../SWScoreThreshold';
import { FlowBasePageType } from './baseTypes';

export type FlowSWScoreOnboardingResultStatStepType = FlowBasePageType & {
	_type: 'FlowSWScoreOnboardingResultStatStep';
	scoreThresholds: SWScoreThresholdType[];
	noScoreContent: BlockContentType;
	calculateScoreCtaText: string;
	statistic: {
		amount: string;
		description: string;
		disclaimer: string;
	};
};

export default defineType({
	name: 'FlowSWScoreOnboardingResultStatStep',
	type: 'document',
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'scoreThresholds',
			type: 'SWScoreThreshold',
		},
		{
			name: 'noScoreContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'calculateScoreCtaText',
			type: 'string',
			initialValue: 'Calculate score now',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			// deprecated
			name: 'startCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
		{
			name: 'statistic',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'amount',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'description',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'disclaimer',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
	preview: previewNamedBlockContent('title'),
});
