import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentMagicLinkApprovalType = Document & {
	_type: 'FragmentMagicLinkApproval';
	askingApproval: {
		heading: InlineContentType;
		subtitle: InlineContentType;
		approveCta: string;
		rejectCta: string;
	};
	displayingCode: {
		heading: InlineContentType;
		subtitle: InlineContentType;
	};
	accessRevoked: {
		heading: InlineContentType;
		subtitle: InlineContentType;
		supportCta: string;
	};
};

export default defineType({
	name: 'FragmentMagicLinkApproval',
	type: 'document',
	fields: [
		{
			name: 'askingApproval',
			type: 'object',
			fields: [
				{
					name: 'heading',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'subtitle',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'approveCta',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'rejectCta',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'displayingCode',
			type: 'object',
			fields: [
				{
					name: 'heading',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'subtitle',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'accessRevoked',
			type: 'object',
			fields: [
				{
					name: 'heading',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'subtitle',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'supportCta',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
	],
});
