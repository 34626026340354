import {
	ExpressionConditionalArrayType,
	ExpressionConditionalBooleanType,
	ExpressionConditionalStringType,
	ExpressionType,
} from './_types';

export const renderExpression = (expression: ExpressionType): string => {
	if (!expression) return '';

	switch (expression._type) {
		case 'ExpressionAppHasMinimumVersion':
			return `appHasMinimumVersion("${expression.minimumVersion}")`;

		case 'ExpressionArrayContainsString':
			return `${renderExpression(
				expression.arrayOperand[0],
			)}.includes(${renderExpression(expression.stringOperand[0])})`;

		case 'ExpressionArrayIsEmpty':
			return `${renderExpression(expression.operand[0])} == []`;

		case 'ExpressionArrayIsNotEmpty':
			return `${renderExpression(expression.operand[0])} != []`;

		case 'ExpressionConditionalArray':
		case 'ExpressionConditionalBoolean':
		case 'ExpressionConditionalString':
			return renderConditional(expression);

		case 'ExpressionConstantArray':
			return `[${expression.value.map((v) => JSON.stringify(v)).join(', ')}]`;

		case 'ExpressionConstantBoolean':
			return `${expression.value}`;

		case 'ExpressionConstantString':
			return `"${expression.value}"`;

		case 'ExpressionContextString':
			return `appContext(${renderExpression(
				expression.contextPathOperand[0],
			)})`;

		case 'ExpressionFeatureIsEnabled':
			return `featureOn(${renderExpression(expression.featureKeyOperand[0])})`;

		case 'ExpressionFeatureValueString':
			return `featureVal(${renderExpression(expression.featureKeyOperand[0])})`;

		case 'ExpressionLogicalAnd':
			return `(${expression.operands.map(renderExpression).join(' AND ')})`;

		case 'ExpressionLogicalNot':
			return `!(${renderExpression(expression.operand[0])})`;

		case 'ExpressionLogicalOr':
			return `(${expression.operands.map(renderExpression).join(' OR ')})`;

		case 'ExpressionPlatformHasCapability':
			return `platformHasCapability("${expression.operand}")`;

		case 'ExpressionPollAnswerKeys':
			return `pollAnswers("${expression.poll.name ?? '[ref]'}")`;

		case 'ExpressionProgramActivityCompleted':
			return `activityCompleted({})`;

		case 'ExpressionProgramActivityNotCompleted':
			return `activityNotCompleted({})`;

		case 'ExpressionReusableArray':
			return renderExpression(expression.operand[0]);

		case 'ExpressionReusableBoolean':
			return renderExpression(expression.operand[0]);

		case 'ExpressionReusableString':
			return renderExpression(expression.operand[0]);

		case 'ExpressionStringIsEqual':
			return `${renderExpression(expression.operands[0])} == ${renderExpression(
				expression.operands[1],
			)}`;

		case 'ExpressionStringIsNotEqual':
			return `${renderExpression(expression.operands[0])} != ${renderExpression(
				expression.operands[1],
			)}`;

		default:
			if ('_ref' in expression) {
				return `[ref]`;
			}

			((_: never) => {
				throw new Error(
					`Unknown expression type: ${JSON.stringify(expression)}`,
				);
			})(expression);
	}
};

const renderConditional = (
	expression:
		| ExpressionConditionalArrayType
		| ExpressionConditionalBooleanType
		| ExpressionConditionalStringType,
): string => {
	const cases = expression.cases.map(
		(c) =>
			`${renderExpression(c.conditionOperand[0])} ? ${renderExpression(
				c.valueOperand[0],
			)} : `,
	);
	const defaultValue = renderExpression(expression.defaultValueOperand[0]);

	return `(${cases.join('')}${defaultValue})`;
};
