import { defineType } from '@sanity/types';

export type IconType = (typeof icons)[number];

const icons = [
	'bell',
	'book_open',
	'chat',
	'check_circle',
	'clock',
	'cloud',
	'credit_card',
	'document',
	'exclamation',
	'external_link',
	'eye',
	'fire',
	'flag',
	'forward',
	'gift',
	'hand',
	'home',
	'information_circle',
	'light_bulb',
	'lightning_bolt',
	'lock_closed',
	'map',
	'menu',
	'moon',
	'refresh',
	'question_mark_circle',
	'search',
	'sparkles',
	'user',
	'users',
	'video_camera',
	'volume_off',
	'volume_up',
	'x',
] as const;

export default defineType({
	name: 'Icon',
	type: 'string',
	options: {
		list: icons.map((icon) => ({
			value: icon,
			title: icon.replace(/_/g, ' '),
		})),
	},
});
