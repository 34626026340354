import { HiOutlineVolumeUp } from '@react-icons/all-files/hi/HiOutlineVolumeUp';
import { defineType, Slug } from '@sanity/types';

import { Document, Image } from './_types';
import { ksuidIdField, previewNamedBlockContent, slugify } from './_utils';
import { AudioType } from './Audio';
import { BlockContentType } from './block/_types';

export type SoundEpisodeType = Document & {
	_type: 'SoundEpisode';
	id: string;
	name: string;
	slug: Slug;
	roughLength: string;
	description: BlockContentType;
	audio: AudioType;
	playerImage: Image;
};

export default defineType({
	name: 'SoundEpisode',
	type: 'document',
	icon: HiOutlineVolumeUp,
	fields: [
		ksuidIdField('sound'),
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'slug',
			type: 'slug',
			validation: (r) => r.required(),
			options: {
				source: 'name',
				slugify,
				isUnique: () => true, // @TODO: check for unique slug under SoundSeries
			},
		},
		{
			name: 'roughLength',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'audio',
			type: 'reference',
			to: [{ type: 'Audio' }],
			validation: (r) => r.required(),
		},
		{
			name: 'description',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'playerImage',
			description: 'Image to display on the audio player screen',
			type: 'image',
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('name', 'description'),
});
