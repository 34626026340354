import { HiLightBulb } from '@react-icons/all-files/hi/HiLightBulb';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export default defineType({
	name: 'ConceptBlock',
	type: 'object',
	icon: HiLightBulb,
	fields: [
		{ name: 'title', type: 'string' },
		{ name: 'content', type: 'BlockContent', validation: (r) => r.required() },
	],
	preview: previewBlockContent(),
});
