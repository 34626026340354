import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';

export type FragmentReferralPartnerType = Document & {
	_type: 'FragmentReferralPartner';
	content: BlockContentType;
	ctaText: string;
	promoCodeLabel: string;
};

export default defineType({
	name: 'FragmentReferralPartner',
	type: 'document',
	fields: [
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'promoCodeLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
