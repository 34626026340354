import { HiVariable } from '@react-icons/all-files/hi/HiVariable';
import { defineType } from '@sanity/types';

import { validateSnakeCase } from '../_utils';

export type TrackingButtonType = {
	_type: 'TrackingButton';
	buttonType: 'primary' | 'secondary' | 'warning';
	buttonTitle: string;
	buttonTitlePressed: string;
	trackingKey: string;
	trackingParams?: TrackingButtonParam[];
};

type TrackingButtonParam = {
	key: string;
	value: string;
};

export default defineType({
	name: 'TrackingButton',
	type: 'object',
	icon: HiVariable,
	fields: [
		{
			name: 'buttonType',
			type: 'string',
			validation: (r) => r.required(),
			options: {
				list: [
					{ value: 'primary', title: 'Primary' },
					{ value: 'secondary', title: 'Secondary' },
					{ value: 'warning', title: 'Warning' },
				],
			},
		},
		{
			name: 'buttonTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'buttonTitlePressed',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'trackingKey',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'trackingParams',
			type: 'array',
			of: [
				{
					name: 'param',
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required(),
						},
						{ name: 'value', type: 'string', validation: (r) => r.required() },
					],
					preview: {
						select: {
							title: 'key',
							subtitle: 'value',
						},
					},
				},
			],
			validation: (r) =>
				r.custom((params?: TrackingButtonParam[]) => {
					if (!params) return true;
					const keys = new Set(params.map((v) => v.key));
					return keys.size === params.length ? true : 'Duplicate keys';
				}),
		},
	],
	preview: {
		select: {
			buttonTitle: 'buttonTitle',
			trackingKey: 'trackingKey',
			trackingParams: 'trackingParams',
		},
		prepare({
			buttonTitle,
			trackingKey,
			trackingParams,
		}: {
			buttonTitle: string;
			trackingKey: string;
			trackingParams?: TrackingButtonParam[];
		}) {
			const params = trackingParams
				?.map(({ key, value }) => `${key}: ${value}`)
				.join(', ');
			return {
				title: buttonTitle,
				subtitle: `${trackingKey}${params ? ` (${params})` : ''}`,
			};
		},
	},
});
