import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { InlineContentType } from './InlineContent';

export type ProgressTrailType = {
	_type: 'ProgressTrail';
	items: ProgressTrailItem[];
	stepsCompleted: number;
};

type ProgressTrailItem = {
	title: string;
	inlineContent?: InlineContentType;
};

export default defineType({
	name: 'ProgressTrail',
	type: 'object',
	icon: HiCheck,
	fields: [
		{
			name: 'stepsCompleted',
			type: 'number',
			validation: (r) => r.required().min(0),
		},
		{
			name: 'items',
			type: 'array',
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{ name: 'title', type: 'string', validation: (r) => r.required() },
						{ name: 'inlineContent', type: 'InlineContent' },
					],
					preview: previewNamedBlockContent('title', 'inlineContent'),
				},
			],
		},
	],
	preview: {
		select: {
			items: 'items',
		},
		prepare(selection) {
			const { items } = selection as Pick<ProgressTrailType, 'items'>;
			return {
				title: `ProgressTrail - ${items.length} items`,
				subtitle: items.map(({ title }) => title).join(', '),
			};
		},
	},
});
