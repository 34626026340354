import { HiVariable } from '@react-icons/all-files/hi/HiVariable';
import { defineType } from '@sanity/types';

export type EnableNotificationsButtonType = {
	_type: 'EnableNotificationsButton';
	buttonType: 'primary' | 'secondary' | 'warning';
	buttonTitle: string;
	buttonTitleEnabled: string;
};

export default defineType({
	name: 'EnableNotificationsButton',
	type: 'object',
	icon: HiVariable,
	fields: [
		{
			name: 'buttonType',
			type: 'string',
			validation: (r) => r.required(),
			options: {
				list: [
					{ value: 'primary', title: 'Primary' },
					{ value: 'secondary', title: 'Secondary' },
					{ value: 'warning', title: 'Warning' },
				],
			},
		},
		{
			name: 'buttonSmall',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'buttonTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'buttonTitleEnabled',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'buttonTitle',
		},
	},
});
