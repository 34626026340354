import { defineArrayMember, defineType } from '@sanity/types';

import Accordion from '../block/Accordion';
import BillingBlock from '../block/BillingBlock';
import Checklist from '../block/Checklist';
import CheckoutPriceBlock from '../block/CheckoutPriceBlock';
import ConceptBlock from '../block/ConceptBlock';
import EnableNotificationsButton from '../block/EnableNotificationsButton';
import ExpertQuote from '../block/ExpertQuote';
import Flair from '../block/Flair';
import Hr from '../block/Hr';
import InfoCardBlock from '../block/InfoCardBlock';
import MemorableBlock from '../block/MemorableBlock';
import ProgressTrail from '../block/ProgressTrail';
import ResearchCarousel from '../block/ResearchCarousel';
import ScienceBlock from '../block/ScienceBlock';
import TrackingButton from '../block/TrackingButton';
import VideoKeyPoints from '../block/VideoKeyPoints';
import WarningBlock from '../block/WarningBlock';
import ContentVariable from '../fields/ContentVariable';
import Link from '../fields/Link';
import ContentCardBlock from './ContentCardBlock';
import ContentCardList from './ContentCardList';
import ContentSwitcherBlock from './ContentSwitcherBlock';
import ExpertChip from './ExpertChip';
import NumberedList from './NumberedList';
import QuoteCardBlock from './QuoteCardBlock';
import TileWithExplanation from './TileWithExplanationList';

export default defineType({
	name: 'BlockContent',
	type: 'array',
	of: [
		{
			type: 'block',
			marks: {
				annotations: [ContentVariable, Link],
			},
		},
		defineArrayMember(Accordion),
		defineArrayMember(BillingBlock),
		defineArrayMember(Checklist),
		defineArrayMember(CheckoutPriceBlock),
		defineArrayMember(ConceptBlock),
		defineArrayMember(ContentCardBlock),
		defineArrayMember(ContentCardList),
		defineArrayMember(ContentSwitcherBlock),
		defineArrayMember(EnableNotificationsButton),
		defineArrayMember(ExpertChip),
		defineArrayMember(ExpertQuote),
		defineArrayMember(Flair),
		defineArrayMember(Hr),
		defineArrayMember(MemorableBlock),
		defineArrayMember(NumberedList),
		defineArrayMember(ProgressTrail),
		defineArrayMember(QuoteCardBlock),
		defineArrayMember(ScienceBlock),
		defineArrayMember(TileWithExplanation),
		defineArrayMember(TrackingButton),
		defineArrayMember(VideoKeyPoints),
		defineArrayMember(WarningBlock),
		defineArrayMember(InfoCardBlock),
		defineArrayMember(ResearchCarousel),
		{ type: 'image' },
		{
			type: 'reference',
			to: [
				{ type: 'Animation' },
				{ type: 'Audio' },
				{ type: 'Carousel' },
				{ type: 'Video' },
			],
		},
	],
});
