import { HiOutlineCalendar } from '@react-icons/all-files/hi/HiOutlineCalendar';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBasePageType } from './baseTypes';

export type FlowCalendlyBookingStepType = FlowBasePageType & {
	_type: 'FlowCalendlyBookingStep';
	content: BlockContentType;
	calendlyUrl: string;
	contentAppend?: BlockContentType;
};

export default defineType({
	name: 'FlowCalendlyBookingStep',
	type: 'document',
	icon: HiOutlineCalendar,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'calendlyUrl',
			type: 'url',
			validation: (r) => r.required(),
		},
		{
			name: 'contentAppend',
			type: 'BlockContent',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
