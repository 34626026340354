import { defineType } from '@sanity/types';

import { ActionBaseFields, ActionBaseType } from './_utils';

export type LogoutActionType = ActionBaseType & {
	_type: 'logout';
};

export default defineType({
	name: 'logout',
	type: 'object',
	fields: ActionBaseFields({
		defaultTitle: 'Log out',
	}),
});
