import { CgGym } from '@react-icons/all-files/cg/CgGym';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewBlockContent } from '../_utils';
import { FlowBaseStepType } from './baseTypes';

export type FlowExerciseTimedImageStepType = FlowBaseStepType & {
	_type: 'FlowExerciseTimedImageStep';
	durationSeconds: number;
	image: Image;
};

export default defineType({
	name: 'FlowExerciseTimedImageStep',
	type: 'document',
	icon: CgGym,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'durationSeconds',
			type: 'number',
			validation: (r) => r.required().integer().positive(),
		},
		{
			name: 'image',
			type: 'image',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Complete',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewBlockContent(),
});
