import { defineType, Reference } from '@sanity/types';

import { Document } from '../_types';
import { ChallengeType } from '../Challenge';

export type FragmentChallengesType = Document & {
	_type: 'FragmentChallenges';
	challenges: ChallengeType[];
};

export default defineType({
	name: 'FragmentChallenges',
	type: 'document',
	fields: [
		{
			name: 'challenges',
			type: 'array',
			description:
				'The order matters here, as the first one that is applicable to the user is the one they get',
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom((challenges: Reference[]) => {
						if (
							challenges.length !== new Set(challenges.map((e) => e._ref)).size
						) {
							return 'Challenges must be unique';
						}
						return true;
					}),
			of: [
				{
					name: 'challenge',
					type: 'reference',
					to: [{ type: 'Challenge' }],
				},
			],
		},
	],
	preview: {
		select: {
			challenges: 'challenges',
		},
		prepare: (selection) => ({
			title: 'Challenges',
			subtitle: selection.challenges.length,
		}),
	},
});
