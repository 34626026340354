import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

type AuthFieldType = {
	continueWithGoogleCtaText: string;
	continueWithAppleCtaText: string;
	continueWithMagicLinkCtaText: string;
	googleCtaText: string;
	appleCtaText: string;
	emailInputLabel: string;
	magicLinkCtaText: string;
	footerCtaText: string;
};

type RegisterScore = AuthFieldType & {
	headingText: InlineContentType;
	emailInputPlaceholder: string;
	subheadingText: InlineContentType;
	emailMarketingConsentText: string;
};

export type FragmentMagicAuthType = Document & {
	_type: 'FragmentMagicAuth';
	login: AuthFieldType;
	register: AuthFieldType & {
		headingText: InlineContentType;
		emailMarketingConsentText: string;
	};
	termsAndPrivacyText: InlineContentType;
	sendingEmailText: string;
	alreadyRegistered: {
		loginCtaText: string;
		tryAnotherEmailCtaText: string;
	};
	registerSWScore: RegisterScore;
	registerSQScore: RegisterScore;
	termsAndPrivacyTextSWScore: InlineContentType;
	disclaimer: InlineContentType;
	fromActivity: {
		title: InlineContentType;
		headingText: InlineContentType;
	};
};

const authFields = [
	defineField({
		name: 'continueWithGoogleCtaText',
		type: 'string',
		validation: (r) => r.required(),
		initialValue: 'Continue with Google',
	}),
	defineField({
		name: 'googleCtaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'continueWithAppleCtaText',
		type: 'string',
		validation: (r) => r.required(),
		initialValue: 'Continue with Apple',
	}),
	defineField({
		name: 'appleCtaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'emailInputLabel',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'continueWithMagicLinkCtaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'magicLinkCtaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'footerCtaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'FragmentMagicAuth',
	type: 'document',
	fields: [
		{
			name: 'login',
			type: 'object',
			fields: authFields,
			validation: (r) => r.required(),
		},
		{
			name: 'register',
			type: 'object',
			fields: [
				...authFields,
				{
					name: 'headingText',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'emailMarketingConsentText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'termsAndPrivacyText',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'sendingEmailText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'alreadyRegistered',
			type: 'object',
			fields: [
				{
					name: 'loginCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'tryAnotherEmailCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'registerSWScore',
			type: 'object',
			fields: [
				...authFields,
				{
					name: 'headingText',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'subheadingText',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'emailMarketingConsentText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'registerSQScore',
			type: 'object',
			fields: [
				{
					name: 'emailInputPlaceholder',
					type: 'string',
					validation: (r) => r.required(),
				},
				...authFields,
				{
					name: 'headingText',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'subheadingText',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'emailMarketingConsentText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'termsAndPrivacyTextSWScore',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'disclaimer',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'fromActivity',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'headingText',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
	],
});
