import { HiOutlineCollection } from '@react-icons/all-files/hi/HiOutlineCollection';
import { defineType, Reference } from '@sanity/types';

import { ProgramActivityGroupType } from '../program/ProgramActivityGroup';

export type FragmentProgramType = Document & {
	_type: 'FragmentProgram';
	items: ProgramActivityGroupType[];
};

export default defineType({
	name: 'FragmentProgram',
	type: 'document',
	icon: HiOutlineCollection,
	fields: [
		{
			name: 'items',
			type: 'array',
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom((val: { items: Reference[] }[]) => {
						const refs = (val ?? []).flatMap((a) => a.items);
						if (refs.length !== new Set(refs.map((e) => e._ref)).size) {
							return 'Activities must be unique';
						}
						return true;
					}),
			of: [
				{
					name: 'ProgramActivityGroup',
					type: 'ProgramActivityGroup',
				},
			],
		},
	],
	preview: {
		prepare: () => ({
			title: 'Program',
		}),
	},
});
