import { defineType } from '@sanity/types';

import { ExpressionAppHasMinimumVersionType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionAppHasMinimumVersion',
	title: 'App Has Minimum Version',
	type: 'object',
	fields: [
		{
			name: 'minimumVersion',
			type: 'string',
			validation: (r) => r.required().regex(/^(\d+\.){2}\d+$/),
		},
	],
	preview: {
		select: {
			_type: '_type',
			minimumVersion: 'minimumVersion',
		},
		prepare: (expression: ExpressionAppHasMinimumVersionType) => ({
			title: renderExpression(expression),
		}),
	},
});
