import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentWrapperType } from '../block/BlockContentWrapper';

export type FragmentCelebrateCheckoutType = Document & {
	_type: 'FragmentCelebrateCheckout';
	title: string;
	content: BlockContentWrapperType;
	ctaText: string;
	mobileCtaText: string;
};

export default defineType({
	name: 'FragmentCelebrateCheckout',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'reference',
			to: [{ type: 'BlockContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'mobileCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
