import { defineType } from '@sanity/types';

import {
	arrayOfTypesArray,
	arrayOfTypesBoolean,
	ExpressionConditionalArrayType,
} from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionConditionalArray',
	title: 'Conditional List',
	type: 'object',
	fields: [
		{
			name: 'cases',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'conditionOperand',
							title: 'Condition',
							type: 'array',
							validation: (r) => r.required().length(1),
							of: arrayOfTypesBoolean,
						},
						{
							name: 'valueOperand',
							title: 'List',
							type: 'array',
							validation: (r) => r.required().length(1),
							of: arrayOfTypesArray,
						},
					],
				},
			],
		},
		{
			name: 'defaultValueOperand',
			title: 'Default List',
			type: 'array',
			validation: (r) => r.required().length(1),
			of: arrayOfTypesArray,
		},
	],
	preview: {
		select: {
			_type: '_type',
			cases: 'cases',
			defaultValueOperand: 'defaultValueOperand',
		},
		prepare: (expression: ExpressionConditionalArrayType) => ({
			title: renderExpression(expression),
		}),
	},
});
