import { defineField, defineType } from '@sanity/types';

import { Image } from '../_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBaseStepType } from './baseTypes';

export type FlowPelvicFloorExerciseCompleteStepType = FlowBaseStepType & {
	_type: 'FlowPelvicFloorExerciseCompleteStep';
	title: InlineContentType;
	description: InlineContentType;
	progressRingImage: Image;
};

export default defineType({
	name: 'FlowPelvicFloorExerciseCompleteStep',
	type: 'document',
	fields: [
		defineField({
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required(),
		}),
		defineField({
			name: 'description',
			type: 'InlineContent',
			validation: (r) => r.required(),
		}),
		defineField({
			name: 'progressRingImage',
			type: 'image',
			validation: (r) => r.required(),
		}),
	],
});
