import { defineType } from '@sanity/types';

import { arrayOfTypesString, ExpressionContextStringType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionContextString',
	type: 'object',
	title: 'App Context Text',
	fields: [
		{
			name: 'contextPathOperand',
			type: 'array',
			validation: (r) => r.required().length(1),
			of: arrayOfTypesString,
		},
	],
	preview: {
		select: {
			_type: '_type',
			contextPathOperand: 'contextPathOperand',
		},
		prepare: (expression: ExpressionContextStringType) => ({
			title: renderExpression(expression),
		}),
	},
});
