import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentMagicLinkRequesterType = Document & {
	_type: 'FragmentMagicLinkRequester';
	title: InlineContentType;
	description: InlineContentType;
	confirmCta: string;
};

export default defineType({
	name: 'FragmentMagicLinkRequester',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'description',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'confirmCta',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
