import { HiLightningBolt } from '@react-icons/all-files/hi/HiLightningBolt';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export default defineType({
	name: 'MemorableBlock',
	type: 'object',
	icon: HiLightningBolt,
	fields: [
		{ name: 'title', type: 'string' },
		{ name: 'content', type: 'BlockContent', validation: (r) => r.required() },
	],
	preview: previewBlockContent(),
});
