import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentCheckoutOneTimeOfferType = Document & {
	_type: 'FragmentCheckoutOneTimeOffer';
	promotionCode: string;
	oneLastThing: {
		title: string;
		content: InlineContentType;
		ctaText: string;
		skipText: string;
	};
	offer: {
		title: string;
		content: InlineContentType;
		freeTrialEnabled: string;
		sevenDayTrial: string;
		yearlyPlan: string;
		billedYearly: string;
		months: string;
		ctaText: string;
		disclaimer: string;
	};
};

export default defineType({
	name: 'FragmentCheckoutOneTimeOffer',
	type: 'document',
	fields: [
		{
			name: 'promotionCode',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'oneLastThing',
			type: 'object',
			options: { collapsible: true },
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'ctaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'skipText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'offer',
			type: 'object',
			options: { collapsible: true },
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'freeTrialEnabled',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'sevenDayTrial',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'yearlyPlan',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'billedYearly',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'months',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'ctaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'disclaimer',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
});
