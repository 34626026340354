import { defineType } from '@sanity/types';

import { InlineContentType } from '../block/InlineContent';

export type FragmentCommunityCreateTopicType = {
	_type: 'FragmentCommunityCreateTopic';
	categorySelection: {
		title: string;
		ctaText: string;
	};
	page: {
		title: InlineContentType;
		postTitleLabel: string;
		postTitlePlaceholder: string;
		postContentLabel: string;
		postContentPlaceholder: string;
		ctaText: string;
	};
};

export default defineType({
	name: 'FragmentCommunityCreateTopic',
	type: 'document',
	fields: [
		{
			name: 'categorySelection',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'ctaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'page',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required().length(1),
				},
				{
					name: 'postTitleLabel',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'postTitlePlaceholder',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'postContentLabel',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'postContentPlaceholder',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'ctaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
});
