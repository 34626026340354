import { defineType, Image } from '@sanity/types';

export type FragmentCoinType = {
	_type: 'FragmentCoin';
	image: Image;
};

export default defineType({
	name: 'FragmentCoin',
	type: 'document',
	fields: [
		{
			name: 'image',
			type: 'image',
			validation: (r) => r.required(),
		},
	],
});
