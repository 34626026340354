import { defineType } from '@sanity/types';

import { validateSnakeCase } from './_utils';
import { BlockContentWrapperType } from './block/BlockContentWrapper';
import { Actions, ActionType } from './fields/actions/Actions';

export type HelpOverpanelSectionType = Document & {
	_type: 'HelpOverpanelSection';
	title: string;
	tabs: HelpSectionTab[];
};

type BlockContentTab = {
	name: string;
	key: string;
	contentType: 'blockContent';
	content: BlockContentWrapperType;
};

type ActionTab = {
	name: string;
	key: string;
	contentType: 'action';
	actionContent: ActionType[];
};

type HelpSectionTab = BlockContentTab | ActionTab;

export default defineType({
	name: 'HelpOverpanelSection',
	title: 'Help Overpanel Section',
	type: 'document',
	fields: [
		{
			name: 'title',
			title: 'Help Overpanel Section Title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'tabs',
			title: 'Tabs',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'name',
							title: 'Tab Name',
							type: 'string',
							validation: (r) => r.required().max(30),
							description: 'The name of the tab, max 30 characters',
						},
						{
							name: 'key',
							title: 'Tab Key',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required(),
						},
						{
							name: 'contentType',
							title: 'Content Type',
							type: 'string',
							options: {
								list: [
									{ title: 'Block Content', value: 'blockContent' },
									{ title: 'Action', value: 'action' },
								],
							},
							validation: (r) => r.required(),
						},
						{
							name: 'content',
							title: 'Block Content',
							type: 'reference',
							to: [{ type: 'BlockContentWrapper' }],
							hidden: ({ parent }) => parent?.contentType !== 'blockContent',
							validation: (r) =>
								r.custom((blockContent, context) => {
									const parent = context.parent as HelpSectionTab;
									if (parent?.contentType === 'blockContent' && !blockContent) {
										return 'Block content is required when content type is set to "Block Content"';
									}
									return true;
								}),
						},
						{
							name: 'actionContent',
							title: 'Action Content',
							type: 'array',
							of: Actions,
							hidden: ({ parent }) => parent?.contentType !== 'action',
							validation: (r) =>
								r.custom((actionContent, context) => {
									const parent = context.parent as HelpSectionTab;
									if (parent?.contentType === 'action' && !actionContent) {
										return 'Action content is required when content type is set to "Action"';
									}
									return true;
								}),
						},
					],
				},
			],
		},
	],
	preview: {
		select: {
			title: 'title',
		},
		prepare(selection) {
			const { title } = selection;
			return {
				title,
			};
		},
	},
});
