import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentType } from '../block/_types';

export type FragmentCookieConsentType = Document & {
	_type: 'FragmentCookieConsent';
	title: string;
	content: BlockContentType;
	acceptCta: string;
	rejectCta: string;
	manageCta: string;
	preferencesAcceptCta: string;
	preferencesSaveCta: string;
	preferencesTitle: string;
	preferencesContent: BlockContentType;
	preferencesAnalyticsTitle: string;
	preferencesAnalyticsContent: BlockContentType;
	preferencesAdvertisingTitle: string;
	preferencesAdvertisingContent: BlockContentType;
};

export default defineType({
	name: 'FragmentCookieConsent',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'acceptCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'rejectCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'manageCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesAcceptCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesSaveCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesAnalyticsTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesAnalyticsContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesAdvertisingTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'preferencesAdvertisingContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
	],
});
