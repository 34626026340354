import { RiPsychotherapyFill } from '@react-icons/all-files/ri/RiPsychotherapyFill';
import { defineType } from '@sanity/types';

import { Document } from './_types';
import { SoundSeriesType } from './SoundSeries';

export type TherapySessionType = Document & {
	_type: 'TherapySession';
	series: Array<SoundSeriesType>;
};

export default defineType({
	name: 'TherapySession',
	type: 'document',
	icon: RiPsychotherapyFill,
	fields: [
		{
			name: 'series',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					name: 'SoundSeries',
					type: 'reference',
					to: [{ type: 'SoundSeries' }],
				},
			],
		},
	],
	preview: {
		select: {
			series: 'series',
		},
		prepare({ series }: { series: SoundSeriesType[] }) {
			return {
				title: 'Therapy Sessions',
				subtitle: `${series.length} series`,
			};
		},
	},
});
