import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from './InlineContent';

export type InlineContentWrapperType = Document & {
	_type: 'InlineContentWrapper';
	content: InlineContentType;
};

export default defineType({
	name: 'InlineContentWrapper',
	type: 'document',

	fields: [
		{
			name: 'content',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
	],

	preview: {
		select: {
			content: 'content',
		},
		prepare: (selection) => {
			// based on https://www.sanity.io/docs/previewing-block-content
			const content = (selection.content ?? []) as InlineContentType;
			const block = content.find((b) => b._type === 'block');
			return {
				title:
					block && 'children' in block
						? block.children
								.filter((child) => child._type === 'span')
								.map((span) => span.text)
								.join('')
						: 'No title',
			};
		},
	},
});
