import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentWrapperType } from '../block/BlockContentWrapper';
import { InlineContentWrapperType } from '../block/InlineContentWrapper';

export type FragmentReferType = Document & {
	_type: 'FragmentRefer';
	title: InlineContentWrapperType;
	content: BlockContentWrapperType;
	referTitle: InlineContentWrapperType;
	discountText: BlockContentWrapperType;
	discountCode: string;
	shareCtaMobile: string;
	shareCtaWeb: string;
	codeCopied: string;
	shareText: string;
};

export default defineType({
	name: 'FragmentRefer',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'reference',
			to: [{ type: 'BlockContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'referTitle',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'discountText',
			type: 'reference',
			to: [{ type: 'BlockContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'discountCode',
			type: 'string',
			description:
				"❗️ If updating the discount code, don't forget to also update the `Share Text` copy.",
			validation: (r) => r.required(),
		},
		{
			name: 'shareCtaMobile',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'shareCtaWeb',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'codeCopied',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'shareText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
