import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBasePageType } from './baseTypes';

export type FlowOnboardingActivityStepType = FlowBasePageType & {
	_type: 'FlowOnboardingActivityStep';
	content: BlockContentType;
	sheet: {
		image: Image;
		title: InlineContentType;
		content: InlineContentType;
		ctaText: string;
		skipCta: string;
	};
};

export default defineType({
	name: 'FlowOnboardingActivityStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'sheet',
			type: 'object',
			fields: [
				{
					name: 'image',
					type: 'image',
					validation: (r) => r.required(),
				},
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'ctaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'skipCta',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('title'),
});
