import { defineType, PortableTextBlock, PortableTextSpan } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentType } from './_types';

export type BlockContentWrapperType = Document & {
	_type: 'BlockContentWrapper';
	content: BlockContentType;
};

export default defineType({
	name: 'BlockContentWrapper',
	type: 'document',

	fields: [
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
	],

	preview: {
		select: {
			content: 'content',
		},
		prepare: (selection) => {
			// based on https://www.sanity.io/docs/previewing-block-content
			const content = (selection.content ?? []) as BlockContentType;
			const block = content.find((b) => b._type === 'block');
			return {
				title:
					block && 'children' in block
						? block.children
								.filter((child: PortableTextBlock) => child._type === 'span')
								.map((span: PortableTextSpan) => span.text)
								.join('')
						: 'No title',
			};
		},
	},
});
