import { defineType } from '@sanity/types';

import { validateSnakeCase } from './_utils';

export type EfficacyAnswerType = {
	_type: 'EfficacyAnswer';
	key: string;
	text: string;
	scoringValue?: number;
};

export default defineType({
	name: 'EfficacyAnswer',
	type: 'object',
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
		{
			name: 'text',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'scoringValue',
			type: 'number',
			validation: (r) => r.integer().positive(),
		},
	],
	preview: {
		select: {
			key: 'key',
			text: 'text',
			scoringValue: 'scoringValue',
		},
		prepare({
			key,
			text,
			scoringValue,
		}: {
			key: string;
			text: string;
			scoringValue?: number;
		}) {
			return {
				title: `${scoringValue ?? 'N/A'} - ${key}`,
				subtitle: text,
			};
		},
	},
});
