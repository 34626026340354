import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentWrapperType } from '../block/BlockContentWrapper';

type ModalFragment = {
	title: string;
	content: BlockContentWrapperType;
	ctaText: string;
	secondaryCtaText?: string;
};

export type FragmentKickstartChallengeType = Document & {
	_type: 'FragmentKickstartChallenge';
	bannerTitle: string;
	summaryModal: ModalFragment;
	congratulationsModal: ModalFragment;
	missedModal: ModalFragment;
};

const modalContent = [
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'content',
		type: 'BlockContentWrapper',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'ctaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'secondaryCtaText',
		type: 'string',
	}),
];

export default defineType({
	name: 'FragmentKickstartChallenge',
	type: 'document',
	fields: [
		{
			name: 'bannerTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'summaryModal',
			type: 'object',
			validation: (r) => r.required(),
			fields: modalContent,
		},
		{
			name: 'congratulationsModal',
			type: 'object',
			validation: (r) => r.required(),
			fields: modalContent,
		},
		{
			name: 'missedModal',
			type: 'object',
			validation: (r) => r.required(),
			fields: modalContent,
		},
	],
});
