import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';

export type FragmentCheckoutPaymentType = Document & {
	_type: 'FragmentCheckoutPayment';
	headerTitle: string;
	calloutText: string;
	ctaText: string;
	termsLink: {
		label: string;
		externalLink: string;
	};
	privacyLink: {
		label: string;
		externalLink: string;
	};
	restorePurchasesLink: {
		label: string;
	};
};

const footerLink = [
	defineField({
		name: 'label',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'externalLink',
		type: 'url',
		validation: (r) => r.uri({ scheme: ['http', 'https'] }),
	}),
];

export default defineType({
	name: 'FragmentCheckoutPayment',
	type: 'document',
	fields: [
		{
			name: 'headerTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'calloutText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'termsLink',
			type: 'object',
			validation: (r) => r.required(),
			fields: footerLink,
		},
		{
			name: 'privacyLink',
			type: 'object',
			validation: (r) => r.required(),
			fields: footerLink,
		},
		{
			name: 'restorePurchasesLink',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'label',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
});
