import { defineType } from '@sanity/types';

import { Document } from '../_types';

export type FragmentOptInNotificationsCardType = Document & {
	_type: 'FragmentOptInNotificationsCard';
	title: string;
	description: string;
	primaryCta: string;
	secondaryCta: string;
};

export default defineType({
	name: 'FragmentOptInNotificationsCard',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			title: 'Title',
			validation: (r) => r.required(),
		},
		{
			name: 'description',
			type: 'string',
			title: 'Description',
			validation: (r) => r.required(),
		},
		{
			name: 'primaryCta',
			type: 'string',
			title: 'Primary CTA',
			initialValue: 'Enable notifications',
			validation: (r) => r.required(),
		},
		{
			name: 'secondaryCta',
			type: 'string',
			title: 'Secondary CTA',
			initialValue: 'Not now',
			validation: (r) => r.required(),
		},
	],
});
