import { HiUser } from '@react-icons/all-files/hi/HiUser';
import { defineType } from '@sanity/types';

export default defineType({
	name: 'ExpertChip',
	type: 'object',
	icon: HiUser,
	fields: [
		{
			name: 'headline',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'author',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'authorImage',
			type: 'image',
			validation: (r) => r.required(),
		},
		{
			name: 'isVerified',
			type: 'boolean',
		},
	],
	preview: {
		select: {
			title: 'headline',
			subtitle: 'author',
		},
	},
});
