import { HiOutlineSparkles } from '@react-icons/all-files/hi/HiOutlineSparkles';
import { defineField, defineType } from '@sanity/types';

import { Document, Image } from './_types';
import { InlineContentType } from './block/InlineContent';
import { Actions, ActionType } from './fields/actions/Actions';

export type CarouselType = Document & {
	_type: 'Carousel';
	name: string;
	variant: 'iconImage'; // TileCardVariant
	tileSize: number;
	tileAspectRatio: number;
	tiles: CarouselTile[];
};

type CarouselTile = {
	title: string;
	subtitle: InlineContentType;
	image: Image;
	action?: [ActionType] | [];
};

const tileFields = [
	defineField({
		name: 'image',
		type: 'image',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'subtitle',
		type: 'InlineContent',
		validation: (r) => r.required().length(1),
	}),
	defineField({
		name: 'action',
		type: 'array',
		validation: (r) => r.max(1),
		of: Actions,
	}),
];

export default defineType({
	name: 'Carousel',
	type: 'document',
	icon: HiOutlineSparkles,
	fields: [
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'variant',
			type: 'string',
			validation: (r) => r.required(),
			options: {
				list: [
					// TileCardVariant.iconImage
					{
						title: 'Icon Image',
						value: 'iconImage',
					},
				],
			},
		},
		{
			name: 'tileSize',
			type: 'number',
			validation: (r) => r.required().integer().positive(),
			initialValue: 250,
		},
		{
			name: 'tileAspectRatio',
			type: 'number',
			validation: (r) => r.required().min(0.01),
			initialValue: 1.15,
		},
		{
			name: 'tiles',
			type: 'array',
			of: [
				{
					name: 'tile',
					type: 'object',
					fields: tileFields,
					preview: {
						select: {
							title: 'title',
						},
					},
				},
			],
		},
	],
	preview: {
		select: {
			title: 'name',
		},
	},
});
