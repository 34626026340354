import { defineType } from '@sanity/types';

import { Document } from '../_types';

export type FragmentPhaseCardPaymentFailedType = Document & {
	_type: 'FragmentPhaseCardPaymentFailed';
	title: string;
	body: string;
	ctaText: string;
};

export default defineType({
	name: 'FragmentPhaseCardPaymentFailed',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'body',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'title',
			subtitle: 'body',
		},
	},
});
