import { HiInformationCircle } from '@react-icons/all-files/hi/HiInformationCircle';
import { getExtension } from '@sanity/asset-utils';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { InlineContentWrapperType } from './InlineContentWrapper';

export type ContentCardBlockType = {
	_type: 'ContentCardBlock';
	image?: Image;
	content: InlineContentWrapperType;
};

export default defineType({
	name: 'ContentCardBlock',
	type: 'object',
	icon: HiInformationCircle,
	fields: [
		{
			name: 'image',
			type: 'image',
			validation: (r) => {
				return r.custom<SanityImageObject>((value) => {
					if (value && getExtension(value.asset._ref) !== 'svg') {
						return 'Only SVG images are allowed';
					}
					return true;
				});
			},
		},
		{
			name: 'content',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			content: 'content',
		},
		prepare: ({ content }) => {
			return {
				title: `Content ref - ${content._ref}`,
			};
		},
	},
});
