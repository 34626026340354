import { BiDollar } from '@react-icons/all-files/bi/BiDollar';
import { defineField } from '@sanity/types';

const style = {
	backgroundColor: 'var(--card-code-bg-color)',
	boxShadow: '0 0 1px 1px var(--card-code-fg-color)',
	color: 'var(--card-code-fg-color)',
	padding: 2,
	paddingLeft: 4,
	paddingRight: 4,
	fontSize: '0.9em',
	borderRadius: 3,
	fontFamily: 'monospace',
};

const ContentVariableComponent = (props: { textElement: string }) => {
	/*
		Default Annotation Component for reference:
		https://github.com/sanity-io/sanity/blob/90b7d959cde754893c14e5f43b67cfa497643dd8/packages/sanity/src/core/form/inputs/PortableText/object/Annotation.tsx#L285

		Note we don't utilise the fields, so we don't render the tooltip UI for them
	*/
	return <span style={style}>{props.textElement}</span>;
};

const ContentVariable = defineField({
	name: 'contentVariable',
	title: 'Content Variable',
	type: 'object',
	icon: BiDollar,
	// @ts-ignore Missing type for components
	components: { annotation: ContentVariableComponent },
	fields: [
		{
			/*
				Sanity requires at least one field to be defined, however we utilise the
				textElement value directly, so this is just a dummy field
			*/
			name: '_dummy',
			type: 'boolean',
		},
	],
});

export default ContentVariable;
