import { HiClipboardList } from '@react-icons/all-files/hi/HiClipboardList';
import { defineType } from '@sanity/types';

export type NumberedListType = {
	_type: 'NumberedList';
	items: { title: string }[];
};

export default defineType({
	name: 'NumberedList',
	type: 'object',
	icon: HiClipboardList,
	fields: [
		{
			name: 'items',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{ name: 'title', type: 'string', validation: (r) => r.required() },
					],
					preview: {
						select: {
							title: 'title',
						},
					},
				},
			],
		},
	],
	preview: {
		select: {
			items: 'items',
		},
		prepare(selection) {
			const { items } = selection as Pick<NumberedListType, 'items'>;
			return {
				title: `NumberedList - ${items.length} items`,
				subtitle: items.map(({ title }) => title).join(', '),
			};
		},
	},
});
