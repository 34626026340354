import { HiOutlineViewBoards } from '@react-icons/all-files/hi/HiOutlineViewBoards';
import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { FlowItemType, flowStepTypes } from './_types';

export type FlowType = Document & {
	_type: 'Flow';
	items: FlowItemType[];
};

export default defineType({
	name: 'Flow',
	type: 'document',
	icon: HiOutlineViewBoards,
	fields: [
		{
			name: 'items',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'reference',
					to: [
						{ type: 'FlowGroup' },
						...flowStepTypes.map((type) => ({ type })),
					],
				},
			],
			description:
				'Steps/groups to be shown conditionally or otherwise in sequence',
		},
	],
	preview: {
		select: {
			items: 'items',
		},
		prepare: ({ items }: FlowType) => ({
			title: items?.length === 1 ? '1 item' : `${items?.length ?? 0} items`,
		}),
	},
});
