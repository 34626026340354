import { defineType } from '@sanity/types';

import { ExpressionPlatformHasCapabilityType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionPlatformHasCapability',
	title: 'Platform Has Capability',
	type: 'object',
	fields: [
		{
			name: 'operand',
			type: 'string',
			title: 'Capability',
			validation: (r) => r.required(),
			options: {
				list: [{ title: 'Notifications', value: 'notifications' }],
			},
		},
	],
	preview: {
		select: {
			_type: '_type',
			operand: 'operand',
		},
		prepare: (expression: ExpressionPlatformHasCapabilityType) => ({
			title: renderExpression(expression),
		}),
	},
});
