import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { SWScoreThresholdType } from '../SWScoreThreshold';
import { FlowBasePageType } from './baseTypes';

export type FlowSWScoreOnboardingResultStepType = FlowBasePageType & {
	_type: 'FlowSWScoreOnboardingResultStep';
	scoreThresholds: SWScoreThresholdType[];
	noScoreContent: BlockContentType;
	calculateScoreCtaText: string;
};

export default defineType({
	name: 'FlowSWScoreOnboardingResultStep',
	type: 'document',
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'scoreThresholds',
			type: 'SWScoreThreshold',
		},
		{
			name: 'noScoreContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'calculateScoreCtaText',
			type: 'string',
			initialValue: 'Calculate score now',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
