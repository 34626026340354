import { defineType } from '@sanity/types';

import { arrayOfTypesString, ExpressionStringIsEqualType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionStringIsEqual',
	title: 'Text Is Equal',
	type: 'object',
	fields: [
		{
			name: 'operands',
			title: 'Text',
			type: 'array',
			validation: (r) => r.required().length(2),
			of: arrayOfTypesString,
		},
	],
	preview: {
		select: {
			_type: '_type',
			operands: 'operands',
		},
		prepare: (expression: ExpressionStringIsEqualType) => ({
			title: renderExpression(expression),
		}),
	},
});
