import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentMagicAuthEmailSentType = Document & {
	_type: 'FragmentMagicAuthEmailSent';
	title: string;
	subtitle: InlineContentType;
	faqsCtaText: string;
	faqsLink: string;
	changeEmailCtaText: string;
	openInboxCtaText: string;
};

export default defineType({
	name: 'FragmentMagicAuthEmailSent',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'subtitle',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'openInboxCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'faqsCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'faqsLink',
			type: 'string',
			validation: (r) => r.uri().required(),
		},
		{
			name: 'changeEmailCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
