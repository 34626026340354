import { defineType } from '@sanity/types';

export type AccountNavigationLinkType = {
	_type: 'AccountNavigationLink';
	label: string;
	linkType: 'internal' | 'external' | 'auto';
	trailingIcon?: 'chevron' | 'external-link';
	externalLink?: string;
	safeExternalLink?: string;
	internalScreen?: string;
};

export default defineType({
	name: 'AccountNavigationLink',
	type: 'object',
	fields: [
		{
			name: 'label',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'linkType',
			type: 'string',
			options: {
				list: [
					{ title: 'Auto', value: 'auto' },
					{ title: 'Internal', value: 'internal' },
					{ title: 'External', value: 'external' },
				],
			},
			validation: (r) => r.required(),
		},
		{
			name: 'trailingIcon',
			type: 'string',
			options: {
				list: [
					{ title: 'Chevron', value: 'chevron' },
					{ title: 'External link', value: 'external-link' },
				],
			},
		},
		{
			name: 'internalScreen',
			type: 'string',
			options: {
				list: [
					{ title: 'Account settings', value: 'AccountSettings' },
					{ title: 'Cancelation', value: 'CancelationQuestion' },
					{ title: 'Checkout', value: 'Checkout' },
					{ title: 'Legal', value: 'AccountLegal' },
					{ title: 'Manage billing', value: 'AccountManageBilling' },
					{ title: 'Preferences', value: 'AccountPreferences' },
					{ title: 'Refer', value: 'Refer' },
					{ title: 'Score overview', value: 'SQScorePillarList' },
				],
			},
			validation: (r) =>
				r.custom((value, context) => {
					const parent = context.parent as AccountNavigationLinkType;
					if (parent.linkType === 'internal' && !value) {
						return 'Select an internal screen for internal link type';
					}
					return true;
				}),
		},
		{
			name: 'externalLink',
			type: 'url',
			validation: (r) =>
				r.uri({ scheme: ['https'] }).custom((value, context) => {
					const parent = context.parent as AccountNavigationLinkType;
					if (parent.linkType === 'external' && !value) {
						return 'External link is required for external link type';
					}
					return true;
				}),
		},
		{
			name: 'safeExternalLink',
			type: 'url',
			validation: (r) =>
				r.uri({ scheme: ['https'] }).custom((value, context) => {
					const parent = context.parent as AccountNavigationLinkType;
					if (Boolean(parent.externalLink) && !value) {
						return 'Safe external link is required for external link type';
					}
					return true;
				}),
		},
	],
});
