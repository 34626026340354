import { defineType } from '@sanity/types';

export type ProgramActivityFilterMaxAgeType = {
	_type: 'ProgramActivityFilterMaxAge';
	maxAgeDays: number;
};

export default defineType({
	name: 'ProgramActivityFilterMaxAge',
	type: 'object',
	fields: [
		{
			name: 'maxAgeDays',
			type: 'number',
			validation: (r) => r.required().integer().min(1),
		},
	],
	preview: {
		select: {
			maxAgeDays: 'maxAgeDays',
		},
		prepare: ({ maxAgeDays }) => ({
			title: `Max age: ${maxAgeDays} days`,
		}),
	},
});
