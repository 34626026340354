import { HiChevronDoubleDown } from '@react-icons/all-files/hi/HiChevronDoubleDown';
import { defineType } from '@sanity/types';

import { AccordionItem, AccordionType } from './_types';

export default defineType({
	name: 'Accordion',
	type: 'object',
	icon: HiChevronDoubleDown,
	fields: [
		{
			name: 'items',
			type: 'array',
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{ name: 'title', type: 'string', validation: (r) => r.required() },
						{
							name: 'content',
							type: 'BlockContent',
							validation: (r) => r.required(),
						},
					],
					preview: {
						select: {
							title: 'title',
						},
						prepare: (selection) => {
							const { title } = selection as Pick<AccordionItem, 'title'>;
							return { title };
						},
					},
				},
			],
		},
	],
	preview: {
		select: {
			items: 'items',
		},
		prepare(selection) {
			const { items } = selection as Pick<AccordionType, 'items'>;
			return {
				title: `Accordion - ${items.length} items`,
				subtitle: items.join(', '),
			};
		},
	},
});
