import { HiSwitchHorizontal } from '@react-icons/all-files/hi/HiSwitchHorizontal';
import { defineArrayMember, defineType } from '@sanity/types';

import { validateDynamicContent, validateSnakeCase } from '../_utils';
import {
	ContentSwitcherBlockType,
	ContentSwitcherChoiceType,
	ContentSwitcherOptionType,
} from './_types';

const SelectionOptionSchema = defineArrayMember({
	type: 'object',
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});

const ChoicesSectionSchema = defineArrayMember({
	type: 'object',
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'columns',
			type: 'number',
			validation: (r) => r.required().integer().min(1),
			initialValue: 2,
		},
		{
			name: 'options',
			type: 'array',
			of: [SelectionOptionSchema],
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom<ContentSwitcherOptionType[]>((val) => {
						if (val && val.length !== new Set(val.map((e) => e.key)).size) {
							return 'Option keys must be unique';
						}
						return true;
					}),
		},
	],
});

export default defineType({
	name: 'ContentSwitcherBlock',
	type: 'object',
	description: 'A block that allows you to switch between different content',
	icon: HiSwitchHorizontal,
	fields: [
		{
			name: 'choices',
			type: 'array',
			of: [ChoicesSectionSchema],
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom<ContentSwitcherChoiceType[]>((val) => {
						if (val && val.length !== new Set(val.map((e) => e.key)).size) {
							return 'Choice keys must be unique';
						}
						return true;
					}),
		},
		{
			name: 'dynamicContent',
			type: 'array',
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom(validateDynamicContent<ContentSwitcherBlockType>),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'content',
							type: 'BlockContent',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
	],
	preview: {
		select: {
			choices: 'choices',
		},
		prepare({ choices }: { choices?: ContentSwitcherChoiceType[] }) {
			if (!choices) return { title: 'No choices, please add' };

			return {
				title: `${choices.length} choices`,
				subtitle: choices.map((option) => option.title).join(', '),
			};
		},
	},
});
