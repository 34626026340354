import { Asset, defineField, defineType } from '@sanity/types';

import { Document } from '../_types';

export type GoalCard = {
	key: string;
	title: string;
	description: string;
	image: { asset: Asset };
};

export type FragmentGoalCardType = Document & {
	_type: 'FragmentGoalCard';
	title: string;
	pollQuestion: string;
	activeGoalLabel: string;
	goalsTitle: string;
	goals: GoalCard[];
	ctaButtonTitle: string;
};

const goalCardFields = [
	defineField({
		name: 'key',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'description',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'image',
		type: 'image',
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'FragmentGoalCard',
	type: 'document',
	fields: [
		{
			name: 'pollQuestion',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'activeGoalLabel',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'goalsTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'goals',
			type: 'array',
			of: [
				{
					name: 'goal',
					type: 'object',
					fields: goalCardFields,
					preview: {
						select: {
							title: 'title',
						},
					},
				},
			],
		},
		{
			name: 'ctaButtonTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
