import { HiExclamation } from '@react-icons/all-files/hi/HiExclamation';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export default defineType({
	name: 'WarningBlock',
	type: 'object',
	icon: HiExclamation,
	fields: [
		{ name: 'title', type: 'string' },
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
