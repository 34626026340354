import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';

export type FragmentCommunityTopicType = Document & {
	_type: 'FragmentCommunityTopic';
	replyBox: {
		collapsedPlaceholder: string;
		placeholder: string;
		replyTopicTitle: string;
		replyTopicCta: string;
		replyCommentTitle: string;
		replyCommentCta: string;
	};
};

export default defineType({
	name: 'FragmentCommunityTopic',
	type: 'document',
	fields: [
		{
			name: 'replyBox',
			type: 'object',
			fields: [
				{
					name: 'collapsedPlaceholder',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'placeholder',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'replyTopicTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'replyTopicCta',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'replyCommentTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'replyCommentCta',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
	preview: previewBlockContent(),
});
