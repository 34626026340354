import { HiPaperClip } from '@react-icons/all-files/hi/HiPaperClip';
import { defineField, defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export type ResearchCarouselType = {
	_type: 'ResearchCarousel';
	title: string;
	content: ResearchContent[];
};

type ResearchContent = {
	title: string;
	authors: string;
	resourceTitle: string;
	resourceYear: string;
};

const researchCarouselFields = [
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'authors',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'resourceTitle',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'resourceYear',
		type: 'string',
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'ResearchCarousel',
	type: 'object',
	icon: HiPaperClip,
	fields: [
		{ name: 'title', type: 'string', validation: (r) => r.required() },
		{
			name: 'content',
			type: 'array',
			of: [
				{
					name: 'research',
					type: 'object',
					fields: researchCarouselFields,
					preview: {
						select: {
							title: 'title',
						},
					},
				},
			],
		},
	],
	preview: previewBlockContent(),
});
