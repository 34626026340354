import { HiInformationCircle } from '@react-icons/all-files/hi/HiInformationCircle';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export default defineType({
	name: 'InfoCardBlock',
	type: 'object',
	icon: HiInformationCircle,
	fields: [
		{
			name: 'title',
			type: 'string',
			description: 'Title is optional',
		},
		{ name: 'content', type: 'BlockContent', validation: (r) => r.required() },
	],
	preview: previewBlockContent(),
});
