import { defineType } from '@sanity/types';

import { Document } from '../_types';

export type FragmentUnlockActivityType = Document & {
	_type: 'FragmentUnlockActivity';
	ctaText: string;
	safeCtaText: string;
};

export default defineType({
	name: 'FragmentUnlockActivity',
	type: 'document',
	fields: [
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'safeCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
