import { defineType } from '@sanity/types';

import { Document } from '../_types';

export type FragmentRegisterPrefaceExitModalType = Document & {
	_type: 'FragmentRegisterPrefaceExitModal';
	title: string;
	skipCta: string;
	exitCta: string;
	cancelCta: string;
};

export default defineType({
	name: 'FragmentRegisterPrefaceExitModal',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'skipCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'exitCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'cancelCta',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
