import { defineArrayMember } from '@sanity/types';

import ContactSupportAction, {
	ContactSupportActionType,
} from './ContactSupportAction';
import LogoutAction, { LogoutActionType } from './LogoutAction';
import NavigateAction, { NavigateActionType } from './NavigateAction';
import OpenSystemSettingsAction, {
	OpenSystemSettingsActionType,
} from './OpenSystemSettingsAction';
import OpenUrlAction, { OpenUrlActionType } from './OpenUrlAction';
import ReloadAppAction, { ReloadAppActionType } from './ReloadAppAction';

export type {
	ContactSupportActionType,
	LogoutActionType,
	NavigateActionType,
	OpenSystemSettingsActionType,
	OpenUrlActionType,
	ReloadAppActionType,
};

export type ActionType =
	| NavigateActionType
	| LogoutActionType
	| ReloadAppActionType
	| OpenUrlActionType
	| OpenSystemSettingsActionType
	| ContactSupportActionType;

export type ActionTypes = ActionType['_type'];

export const Actions = [
	defineArrayMember(NavigateAction),
	defineArrayMember(LogoutAction),
	defineArrayMember(ReloadAppAction),
	defineArrayMember(OpenUrlAction),
	defineArrayMember(OpenSystemSettingsAction),
	defineArrayMember(ContactSupportAction),
];
