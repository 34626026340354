import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from './_utils';
import { BlockContentType } from './block/_types';

export type SWScoreThresholdType = {
	minScore: number;
	content: BlockContentType;
};

export default defineType({
	name: 'SWScoreThreshold',
	type: 'array',
	of: [
		{
			type: 'object',
			fields: [
				{
					name: 'minScore',
					type: 'number',
					validation: (r) => r.required().integer().min(0).max(100),
				},
				{
					name: 'content',
					type: 'BlockContent',
					validation: (r) => r.required(),
				},
			],
			preview: previewNamedBlockContent('minScore', 'content'),
		},
	],
	validation: (r) =>
		r
			.required()
			.min(1)
			.custom<SWScoreThresholdType[]>((value) => {
				if (!value) return true;
				if (value.length !== new Set(value.map((e) => e.minScore)).size) {
					return 'Thresholds must be unique';
				}
				if (!value.slice(1).every((e, i) => e.minScore < value[i]!.minScore)) {
					return 'Thresholds must be ordered highest-to-lowest';
				}
				const last = value[value.length - 1];
				if (last && last.minScore !== 0) {
					return 'Last threshold must be 0';
				}
				return true;
			}),
});
