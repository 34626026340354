import { HiBeaker } from '@react-icons/all-files/hi/HiBeaker';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export default defineType({
	name: 'ScienceBlock',
	type: 'object',
	icon: HiBeaker,
	fields: [
		{ name: 'title', type: 'string', validation: (r) => r.required() },
		{ name: 'content', type: 'BlockContent', validation: (r) => r.required() },
	],
	preview: previewBlockContent(),
});
