import { defineType } from '@sanity/types';

import { ActionBaseFields, ActionBaseType } from './_utils';

export type NavigateActionType = ActionBaseType & {
	_type: 'navigate';
	screen: string;
	params?: string;
};

export default defineType({
	name: 'navigate',
	type: 'object',
	fields: [
		...ActionBaseFields(),
		{
			title: 'Screen (map to a valid ScreenKey value)',
			name: 'screen',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			title: 'Screen Params (JSON string, e.g. `{ "phaseNo": 1 }`)',
			name: 'params',
			type: 'string',
			validation: (r) =>
				r.custom((value: string) => {
					if (!value) return true;
					try {
						const parsed = JSON.parse(value);
						if (typeof parsed !== 'object' || Array.isArray(parsed)) {
							return `Invalid params - expected a map type object (got "${JSON.stringify(
								parsed,
							)}")`;
						}
						return true;
					} catch (e) {
						const message = 'Invalid JSON string - failed to parse';
						console.error(message, e);
						return message;
					}
				}),
		},
	],
});
