import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { FlowBaseStepType } from './baseTypes';

export type FlowWrapUpStepType = FlowBaseStepType & {
	_type: 'FlowWrapUpStep';
	content: BlockContentType;
	efficacyQuestion: string;
	positiveAnswerOptions: Array<string>;
	negativeAnswerOptions: Array<string>;
	contentAppend?: BlockContentType;
};

export default defineType({
	name: 'FlowWrapUpStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'efficacyQuestion',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'positiveAnswerOptions',
			type: 'array',
			of: [{ type: 'string' }],
			validation: (r) => r.required(),
		},
		{
			name: 'negativeAnswerOptions',
			type: 'array',
			of: [{ type: 'string' }],
			validation: (r) => r.required(),
		},
		{
			name: 'contentAppend',
			type: 'BlockContent',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewBlockContent(),
});
