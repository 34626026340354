import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentType } from '../block/_types';

export type FragmentFreeUserFirstCourseCompletedType = Document & {
	_type: 'FragmentFreeUserFirstCourseCompleted';
	title: string;
	content: BlockContentType;
	ctaText: string;
};

export default defineType({
	name: 'FragmentFreeUserFirstCourseCompleted',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'title',
		},
	},
});
