import { HiUser } from '@react-icons/all-files/hi/HiUser';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export default defineType({
	name: 'ExpertQuote',
	deprecated: {
		reason: 'Use QuoteCardBlock instead going forward',
	},
	type: 'object',
	icon: HiUser,
	fields: [
		{
			name: 'author',
			type: 'string',
			validation: (r) => r.required(),
			deprecated: {
				reason: 'Use QuoteCardBlock instead going forward',
			},
		},
		{
			name: 'authorImage',
			type: 'image',
			deprecated: {
				reason: 'Use QuoteCardBlock instead going forward',
			},
		},
		{
			name: 'byline',
			type: 'string',
			validation: (r) => r.required(),
			deprecated: {
				reason: 'Use QuoteCardBlock instead going forward',
			},
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
			deprecated: {
				reason: 'Use QuoteCardBlock instead going forward',
			},
		},
	],
	preview: previewBlockContent(),
});
