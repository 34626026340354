import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentAppReviewType = Document & {
	_type: 'FragmentAppReview';
	title: InlineContentType;
	content: BlockContentType;
	positiveContent: BlockContentType;
	positiveCtaIOS: string;
	positiveCtaAndroid: string;
	negativeContent: BlockContentType;
	negativeCta: string;
};

export default defineType({
	name: 'FragmentAppReview',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().max(1),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'positiveContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'positiveCtaIOS',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'positiveCtaAndroid',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'negativeContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'negativeCta',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
