import { defineType } from '@sanity/types';

import { ActionBaseFields, ActionBaseType } from './_utils';

export type OpenSystemSettingsActionType = ActionBaseType & {
	_type: 'openSystemSettings';
};

export default defineType({
	name: 'openSystemSettings',
	type: 'object',
	fields: ActionBaseFields({
		defaultTitle: 'Open system settings',
	}),
});
