import { HiOutlineBeaker } from '@react-icons/all-files/hi/HiOutlineBeaker';
import { defineType } from '@sanity/types';

import { Document } from './_types';
import { EfficacyQuestionType } from './EfficacyQuestion';

export type EfficacyQuestionSetVersionType = Document & {
	_type: 'EfficacyQuestionSetVersion';
	version: string;
	questions: EfficacyQuestionType[];
};

export default defineType({
	name: 'EfficacyQuestionSetVersion',
	type: 'document',
	icon: HiOutlineBeaker,
	fields: [
		{
			name: 'version',
			type: 'date',
			validation: (r) => r.required(),
			readOnly: (c) =>
				(c.document as EfficacyQuestionSetVersionType | undefined)
					?.std_published ?? false,
		},
		{
			name: 'questions',
			type: 'array',
			validation: (r) => r.required(),
			readOnly: (c) =>
				(c.document as EfficacyQuestionSetVersionType | undefined)
					?.std_published ?? false,
			of: [{ type: 'EfficacyQuestion' }],
		},
	],
	preview: {
		select: {
			version: 'version',
			questions: 'questions',
		},
		prepare({
			version,
			questions,
		}: {
			version: string;
			questions: EfficacyQuestionType[];
		}) {
			return {
				title: version,
				subtitle: `${questions?.length ?? 0} questions`,
			};
		},
	},
});
