import { HiOutlineUserGroup } from '@react-icons/all-files/hi/HiOutlineUserGroup';
import { Asset, defineType } from '@sanity/types';

export type CommunityExpertType = {
	_type: 'CommunityExpert';
	firstName: string;
	lastName?: string;
	image: { asset: Asset };
	role?: string;
	company?: string;
	intro?: string;
};

export default defineType({
	name: 'CommunityExpert',
	type: 'document',
	icon: HiOutlineUserGroup,
	fields: [
		{
			name: 'isVerified',
			type: 'boolean',
			description:
				'Indicates whether this expert has been verified by our team',
		},
		{
			name: 'firstName',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'lastName',
			type: 'string',
		},
		{
			name: 'image',
			type: 'image',
			validation: (r) => r.required(),
		},
		{
			name: 'role',
			description: 'e.g. "Science lead"',
			type: 'string',
		},
		{
			name: 'company',
			type: 'string',
		},
		{
			name: 'intro',
			description:
				'e.g. "I bring together Mojo experts, new research, and your goals"',
			type: 'string',
		},
	],
	preview: {
		select: {
			firstName: 'firstName',
			lastName: 'lastName',
			role: 'role',
		},
		prepare({ firstName, lastName, role }) {
			return {
				title: `${firstName} ${lastName ?? ''}`,
				subtitle: role,
			};
		},
	},
});
