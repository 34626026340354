import { defineType } from '@sanity/types';

import { BlockContentType } from './block/_types';
import { InlineContentType } from './block/InlineContent';

export type CancelationDiscountOfferType = {
	_type: 'CancelationDiscountOffer';
	headline?: InlineContentType;
	title: string;
	subtitle?: InlineContentType;
	content: BlockContentType;
	acceptOfferCtaText: string;
	declineOfferCtaText: string;
};

export default defineType({
	name: 'CancelationDiscountOffer',
	type: 'object',
	fields: [
		{
			name: 'headline',
			type: 'InlineContent',
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'subtitle',
			type: 'InlineContent',
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'acceptOfferCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'declineOfferCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
