import { CgGym } from '@react-icons/all-files/cg/CgGym';
import { defineField, defineType } from '@sanity/types';

import { File } from '../_types';
import { previewBlockContent } from '../_utils';
import { HelpOverpanelSectionType } from '../HelpOverpanelSection';
import { FlowBaseStepType } from './baseTypes';

export type FlowExerciseTimedBoxBreathAnimationStepType = FlowBaseStepType & {
	_type: 'FlowExerciseTimedBoxBreathAnimationStep';
	durationSeconds: number;
	inhale: TimedBoxBreathControlOption;
	exhale: TimedBoxBreathControlOption;
	hold: TimedBoxBreathControlOption;
	overpanelHelpSection?: HelpOverpanelSectionType;
};

export type TimedBoxBreathControlOption = {
	key: 'inhale' | 'hold' | 'exhale';
	label: string;
	instruction: string;
	duration: number;
	audio?: File;
};

const controlFieds = [
	defineField({
		type: 'string',
		name: 'label',
		validation: (r) => r.required(),
	}),
	defineField({
		type: 'string',
		name: 'instruction',
		validation: (r) => r.required(),
	}),
	defineField({
		type: 'number',
		name: 'duration',
		initialValue: 4,
		validation: (r) => r.required(),
	}),
	defineField({
		type: 'file',
		name: 'audio',
		options: { accept: 'audio/*' },
	}),
];

export default defineType({
	name: 'FlowExerciseTimedBoxBreathAnimationStep',
	type: 'document',
	icon: CgGym,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'inhale',
			type: 'object',
			fields: [
				{
					type: 'string',
					name: 'key',
					readOnly: true,
					initialValue: 'inhale',
				},
				...controlFieds,
			],
			validation: (r) => r.required(),
		},
		{
			name: 'hold',
			type: 'object',
			fields: [
				{
					type: 'string',
					name: 'key',
					readOnly: true,
					initialValue: 'hold',
				},
				...controlFieds,
			],
			validation: (r) => r.required(),
		},
		{
			name: 'exhale',
			type: 'object',
			fields: [
				{
					type: 'string',
					name: 'key',
					readOnly: true,
					initialValue: 'exhale',
				},
				...controlFieds,
			],
			validation: (r) => r.required(),
		},
		{
			name: 'durationSeconds',
			type: 'number',
			validation: (r) => r.required().integer().positive(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Complete',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
		{
			name: 'overpanelHelpSection',
			type: 'reference',
			to: [{ type: 'HelpOverpanelSection' }],
		},
	],
	preview: previewBlockContent(),
});
