import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentMagicAuthRecoveryType = Document & {
	_type: 'FragmentMagicAuthRecovery';
	confirmAccount: {
		confirmCtaText: string;
		declineCtaText: string;
	};
	tryAppleAccount: {
		title: string;
		message: InlineContentType;
		proceedCtaText: string;
		declineCtaText: string;
	};
	support: {
		title: string;
		message: InlineContentType;
		faqCtaText: string;
		faqLink: string;
		cancelCtaText: string;
	};
};

export default defineType({
	name: 'FragmentMagicAuthRecovery',
	type: 'document',
	fields: [
		{
			name: 'confirmAccount',
			type: 'object',
			fields: [
				{
					name: 'confirmCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'declineCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'tryAppleAccount',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'message',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'proceedCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'declineCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
		{
			name: 'support',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'message',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'faqCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'faqLink',
					type: 'string',
					validation: (r) => r.uri().required(),
				},
				{
					name: 'cancelCtaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
			validation: (r) => r.required(),
		},
	],
});
