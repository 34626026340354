import { defineType } from '@sanity/types';

import { ActionBaseFields, ActionBaseType } from './_utils';

export type OpenUrlActionType = ActionBaseType & {
	_type: 'openUrl';
	url: string;
};

export default defineType({
	name: 'openUrl',
	type: 'object',
	fields: [
		...ActionBaseFields(),
		{
			name: 'url',
			type: 'url',
			validation: (r) => r.required(),
		},
	],
});
