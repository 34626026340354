import { defineType } from '@sanity/types';

import { validateSnakeCase } from './_utils';

export type PollOptionType = {
	_type: 'PollOption';
	text: string;
	key: string;
};

export default defineType({
	name: 'PollOption',
	type: 'object',
	fields: [
		{
			name: 'text',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
	],
	preview: {
		select: {
			title: 'key',
			subtitle: 'text',
		},
	},
});
