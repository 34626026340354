import { defineType } from '@sanity/types';

import { validateSnakeCase } from './_utils';
import { EfficacyAnswerType } from './EfficacyAnswer';
import { SQScorePillar } from './SQScorePillar';

export type EfficacyQuestionType = {
	_type: 'EfficacyQuestion';
	key: string;
	text: string;
	helperText?: string;
	pillar?: SQScorePillar;
	answers: EfficacyAnswerType[];
};

export default defineType({
	name: 'EfficacyQuestion',
	type: 'object',
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
		{
			name: 'text',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'helperText',
			type: 'string',
		},
		{
			name: 'pillar',
			type: 'SQScorePillar',
		},
		{
			name: 'answers',
			type: 'array',
			validation: (r) => r.required(),
			of: [{ type: 'EfficacyAnswer' }],
		},
	],
	preview: {
		select: {
			key: 'key',
			text: 'text',
			answers: 'answers',
		},
		prepare({
			key,
			text,
			answers,
		}: {
			key: string;
			text: string;
			answers: EfficacyAnswerType[];
		}) {
			return {
				title: `${key}: ${answers?.length ?? 0} answers`,
				subtitle: text,
			};
		},
	},
});
