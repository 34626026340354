import { defineType } from '@sanity/types';

import { AudioType } from '../Audio';
import { InlineContentType } from '../block/InlineContent';
import { HelpOverpanelSectionType } from '../HelpOverpanelSection';
import { FlowBaseStepType } from './baseTypes';

export type FlowReverseKegelExerciseStepType = FlowBaseStepType & {
	_type: 'FlowReverseKegelExerciseStep';
	beforeStartInstruction: string;
	duringExerciseInstruction: string;
	numberOfReps: number;
	rest: {
		stepName: string;
		heading: string;
		description: InlineContentType;
		duration: number;
		audio: AudioType;
	};
	squeeze: {
		stepName: string;
		heading: string;
		description?: InlineContentType;
		duration: number;
		audio: AudioType;
	};
	interruptedBottomSheet: {
		title: string;
		description: InlineContentType;
		finishCta: string;
		continueCta: string;
	};
	overpanelHelpSection: HelpOverpanelSectionType;
	startButtonText: string;
	finishButtonText: string;
	continueButtonText: string;
};

export default defineType({
	name: 'FlowReverseKegelExerciseStep',
	type: 'document',
	fields: [
		{
			name: 'beforeStartInstruction',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'duringExerciseInstruction',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'numberOfReps',
			type: 'number',
			validation: (r) => r.required(),
		},
		{
			name: 'rest',
			type: 'object',
			fields: [
				{
					name: 'stepName',
					type: 'string',
					initialValue: 'rest',
					validation: (r) => r.required(),
				},
				{
					name: 'heading',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'description',
					type: 'InlineContent',
				},
				{
					name: 'duration',
					description: 'Duration of the rest step in seconds',
					type: 'number',
					validation: (r) => r.required(),
				},
				{
					name: 'audio',
					type: 'reference',
					to: [{ type: 'Audio' }],
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'squeeze',
			type: 'object',
			fields: [
				{
					name: 'stepName',
					type: 'string',
					initialValue: 'squeeze',
					validation: (r) => r.required(),
				},
				{
					name: 'heading',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'description',
					type: 'InlineContent',
				},
				{
					name: 'duration',
					description: 'Duration of the squeeze step in seconds',
					type: 'number',
					validation: (r) => r.required(),
				},
				{
					name: 'audio',
					type: 'reference',
					to: [{ type: 'Audio' }],
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'interruptedBottomSheet',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'description',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'finishCta',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'continueCta',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'overpanelHelpSection',
			type: 'reference',
			to: [{ type: 'HelpOverpanelSection' }],
			validation: (r) => r.required(),
			description: 'Help section for the pelvic exercise step',
		},
		{
			name: 'startButtonText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'finishButtonText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'continueButtonText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			numberOfReps: 'numberOfReps',
			squeezeDuration: 'squeeze.duration',
			restDuration: 'rest.duration',
		},
		prepare: (selection) => ({
			title: `${selection.numberOfReps} reps, ${selection.squeezeDuration}s squeeze, ${selection.restDuration}s rest`,
			subtitle: `${selection.squeezeDuration}s squeeze, ${selection.restDuration}s rest`,
		}),
	},
});
