import { defineType } from '@sanity/types';

import { ActionBaseFields, ActionBaseType } from './_utils';

export type ContactSupportActionType = ActionBaseType & {
	_type: 'contactSupport';
	prefilledMessage?: string;
};

export default defineType({
	name: 'contactSupport',
	type: 'object',
	fields: [
		...ActionBaseFields({
			defaultTitle: 'Contact support',
		}),
		{
			name: 'prefilledMessage',
			type: 'string',
		},
	],
});
