import { HiOutlineChat } from '@react-icons/all-files/hi/HiOutlineChat';
import { defineType } from '@sanity/types';

import { previewBlockContent } from '../_utils';

export default defineType({
	name: 'QuoteCardBlock',
	type: 'object',
	icon: HiOutlineChat,
	fields: [
		{
			name: 'content',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
		{ name: 'author', type: 'string', validation: (r) => r.required() },
		{
			name: 'authorImage',
			type: 'image',
		},
	],
	preview: previewBlockContent(),
});
