import { defineType } from '@sanity/types';

export enum SQScorePillar {
	mentalResilience = 'mental_resilience',
	stimulationResponse = 'stimulation_response',
	sexualFitness = 'sexual_fitness',
	connectionBuilding = 'connection_building',
}

export const sqScorePillarsOrdered: SQScorePillar[] = [
	SQScorePillar.mentalResilience,
	SQScorePillar.stimulationResponse,
	SQScorePillar.sexualFitness,
	SQScorePillar.connectionBuilding,
];

export default defineType({
	name: 'SQScorePillar',
	type: 'string',
	options: {
		list: sqScorePillarsOrdered.map((pillar) => ({
			title:
				pillar.substring(0, 1).toUpperCase() +
				pillar.substring(1).replace(/_/g, ' '),
			value: pillar,
		})),
	},
});
