import { PortableTextBlock } from '@portabletext/types';
import { defineType } from '@sanity/types';

import ContentVariable from '../fields/ContentVariable';
import Link from '../fields/Link';

export type InlineContentType = PortableTextBlock[];

export default defineType({
	name: 'InlineContent',
	type: 'array',
	of: [
		/**
		 * Note: this type is heavily used for rendering Text regions and components,
		 * it should never embed types that render components that are incompatible
		 * as children of the <Text> component.
		 */
		{
			type: 'block',
			styles: [{ title: 'Normal', value: 'normal' }],
			lists: [],
			marks: {
				annotations: [ContentVariable, Link],
			},
		},
	],
});
