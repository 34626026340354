import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { defineType } from '@sanity/types';

export type ChecklistType = {
	_type: 'Checklist';
	items: ChecklistItem[];
};

type ChecklistItem = {
	checked: boolean;
	title: string;
};

export default defineType({
	name: 'Checklist',
	type: 'object',
	icon: HiCheck,
	fields: [
		{
			name: 'items',
			type: 'array',
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{ name: 'checked', type: 'boolean', initialValue: false },
						{ name: 'title', type: 'string', validation: (r) => r.required() },
					],
					preview: {
						select: {
							checked: 'checked',
							title: 'title',
						},
						prepare: ({ checked, title }) => ({
							title: checkbox(checked, title),
						}),
					},
				},
			],
		},
	],
	preview: {
		select: {
			items: 'items',
		},
		prepare(selection) {
			const { items } = selection as Pick<ChecklistType, 'items'>;
			return {
				title: `Checklist - ${items.length} items`,
				subtitle: items
					.map(({ checked, title }) => checkbox(checked, title))
					.join(', '),
			};
		},
	},
});

const checkbox = (checked: boolean, title: string) =>
	`${checked ? '✓ ' : ''}${title}`;
