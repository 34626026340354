import { defineField, defineType } from '@sanity/types';

import { InlineContentWrapperType } from '../block/InlineContentWrapper';

type Recovery = {
	title?: InlineContentWrapperType;
	content: InlineContentWrapperType;
	ctaText: InlineContentWrapperType;
	coinBalanceContent: InlineContentWrapperType;
};

export type FragmentStreaksRecoveryType = {
	_type: 'FragmentStreaksRecovery';
	beforeRecovery: Recovery;
	afterRecovery: Recovery;
};

const fields = [
	defineField({
		name: 'title',
		type: 'reference',
		to: [{ type: 'InlineContentWrapper' }],
	}),
	defineField({
		name: 'content',
		type: 'reference',
		to: [{ type: 'InlineContentWrapper' }],
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'ctaText',
		type: 'reference',
		to: [{ type: 'InlineContentWrapper' }],
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'coinBalanceContent',
		type: 'reference',
		to: [{ type: 'InlineContentWrapper' }],
		validation: (r) => r.required(),
	}),
];

export default defineType({
	name: 'FragmentStreaksRecovery',
	type: 'document',
	fields: [
		{
			name: 'beforeRecovery',
			type: 'object',
			options: {
				collapsible: true,
				collapsed: true,
			},
			validation: (r) => r.required(),
			fields: fields,
		},
		{
			name: 'afterRecovery',
			type: 'object',
			options: {
				collapsible: true,
				collapsed: true,
			},
			validation: (r) => r.required(),
			fields: fields,
		},
	],
});
