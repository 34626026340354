import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { InlineContentType } from '../block/InlineContent';

type Card = {
	title: string;
	body: InlineContentType;
	ctaText: string;
};

export type FragmentStartTrialCardType = Document & {
	_type: 'FragmentStartTrialCard';
	card: Card;
};

export default defineType({
	name: 'FragmentStartTrialCard',
	type: 'document',
	fields: [
		{
			name: 'card',
			type: 'object',
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'body',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'ctaText',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
});
