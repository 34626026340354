import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBasePageType } from './baseTypes';

export type FlowNotificationsPreferenceStepType = FlowBasePageType & {
	_type: 'FlowNotificationsPreferenceStep';
	image: Image;
	subtitle: InlineContentType;
	options: {
		key: string;
		title: string;
	}[];
	content: BlockContentType;
	nextCtaText: string;
	grantedEnableCtaText: string;
	grantedNextCtaText: string;
	turnOff: string;
};

export default defineType({
	name: 'FlowNotificationsPreferenceStep',
	type: 'document',
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'image',
			type: 'image',
			validation: (r) => r.required(),
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'subtitle',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'options',
			type: 'array',
			of: [
				{
					name: 'time',
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'title',
							type: 'string',
							validation: (r) => r.required(),
						},
					],
				},
			],
			validation: (r) => r.required().min(2).max(3),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'nextCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'grantedEnableCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'grantedNextCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'turnOff',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('title'),
});
