import { defineType } from '@sanity/types';

import { Document } from '../_types';

export type FragmentInAppPurchasesType = Document & {
	_type: 'FragmentInAppPurchases';
	iosProducts: Array<{ key: string; name: string }>;
	androidProducts: Array<{ key: string; name: string }>;
	restorePurchaseSucessMessage: string;
	restorePurchaseFailureMessage: string;
};

export default defineType({
	name: 'FragmentInAppPurchases',
	type: 'document',
	fields: [
		{
			name: 'iosProducts',
			type: 'array',
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'name',
							type: 'string',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'androidProducts',
			type: 'array',
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'name',
							type: 'string',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'restorePurchaseSucessMessage',
			type: 'string',
			validation: (r) => r.required().min(1),
		},
		{
			name: 'restorePurchaseFailureMessage',
			type: 'string',
			validation: (r) => r.required().min(1),
		},
	],
});
