import { defineType } from '@sanity/types';

import { arrayOfTypesArray, ExpressionArrayIsNotEmptyType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionArrayIsNotEmpty',
	title: 'List Is Not Empty',
	type: 'object',
	fields: [
		{
			name: 'operand',
			type: 'array',
			title: 'List',
			description: 'List currently refers to type of Poll',
			validation: (r) => r.required().length(1),
			of: arrayOfTypesArray,
		},
	],
	preview: {
		select: {
			_type: '_type',
			operand: 'operand',
		},
		prepare: (expression: ExpressionArrayIsNotEmptyType) => ({
			title: renderExpression(expression),
		}),
	},
});
