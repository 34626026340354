import { defineType } from '@sanity/types';

import { ExpressionConstantBooleanType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionConstantBoolean',
	title: 'Static Yes/No',
	type: 'object',
	fields: [
		{
			name: 'value',
			type: 'boolean',
			initialValue: false,
		},
	],
	preview: {
		select: {
			_type: '_type',
			value: 'value',
		},
		prepare: (expression: ExpressionConstantBooleanType) => ({
			title: renderExpression(expression),
		}),
	},
});
