import { defineType } from '@sanity/types';

import { ExpressionPollAnswerKeysType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionPollAnswerKeys',
	type: 'document',
	fields: [
		{
			name: 'poll',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'Poll' }],
		},
	],
	preview: {
		select: {
			_type: '_type',
			poll: 'poll',
		},
		prepare: (expression: ExpressionPollAnswerKeysType) => ({
			title: renderExpression(expression),
		}),
	},
});
