import { defineType } from '@sanity/types';

import {
	ProgramActivityFilterType,
	programActivityFilterTypes,
} from '../program/_types';
import { ExpressionProgramActivityNotCompletedType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionProgramActivityNotCompleted',
	type: 'document',
	fields: [
		{
			name: 'filters',
			type: 'array',
			of: programActivityFilterTypes.map((type) => ({ type })),
			description: 'Which activities to count',
			validation: (r) =>
				r
					.required()
					.custom<ProgramActivityFilterType[] | undefined>((val) => {
						if (!val?.length) return "You don't have any filters 🧐";
						const typeFilters = val.filter(
							(f) =>
								f._type === 'ProgramActivityFilterExactActivity' ||
								f._type === 'ProgramActivityFilterSingleMetadata',
						);
						switch (typeFilters.length) {
							case 0:
								return 'You might want more specific filtering 👀';
							case 1:
								return true;
							default:
								return 'You might be over-filtering 🤔';
						}
					})
					.warning(),
		},
		{
			name: 'minCount',
			type: 'number',
			validation: (r) => r.required().integer().min(1),
			initialValue: 1,
			description:
				'If you input e.g. 4, it\'d mean "not more than 3 times". Usually best to leave this as 1.',
		},
	],
	preview: {
		select: {
			_type: '_type',
			filters: 'filters',
			minCount: 'minCount',

			// TODO: could we remove these plz
			_id: '_id',
			std_published: 'std_published',
		},
		prepare: (expression: ExpressionProgramActivityNotCompletedType) => ({
			title: renderExpression(expression),
		}),
	},
});
