import { HiCurrencyPound } from '@react-icons/all-files/hi/HiCurrencyPound';
import { defineType } from '@sanity/types';

import { InlineContentType } from './InlineContent';

export type CheckoutPriceBlockType = {
	_type: 'CheckoutPriceBlock';
	label: InlineContentType;
	price: InlineContentType;
	originalPrice?: InlineContentType;
};

export default defineType({
	name: 'CheckoutPriceBlock',
	type: 'object',
	icon: HiCurrencyPound,
	fields: [
		{
			name: 'label',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'price',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'originalPrice',
			type: 'InlineContent',
		},
	],
	preview: {
		select: {
			title: 'label',
			subtitle: 'price',
		},
	},
});
