import { defineField } from '@sanity/types';

export type ActionBaseType = {
	_type: string;
	title: string;
};

export const ActionBaseFields = ({
	defaultTitle,
}: {
	defaultTitle?: string;
} = {}) => [
	defineField({
		name: 'title',
		type: 'string',
		initialValue: defaultTitle,
		validation: (r) => r.required(),
	}),
];
