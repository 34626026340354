import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';

export type FragmentConsentType = Document & {
	_type: 'FragmentConsent';
	content: BlockContentType;
	continueButtonText: string;
	title: string;
	sensitiveDataTitle: string;
	sensitiveDataContent: BlockContentType;
	sensitiveDataContentAppend: BlockContentType;
	agreeAndContinueCta: string;
	declineCta: string;
	privacyPolicyCta: string;
	privacyPolicyLink: string;
	functionalConsentContent: BlockContentType;
	advertisingConsentContent: BlockContentType;
};

export default defineType({
	name: 'FragmentConsent',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'continueButtonText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'sensitiveDataTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'sensitiveDataContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'sensitiveDataContentAppend',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			// deprecated
			name: 'functionalConsentCheckbox',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			// deprecated
			name: 'functionalConsentRequired',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			// deprecated
			name: 'advertisingConsentCheckbox',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			// deprecated
			name: 'sensitiveDataCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			// deprecated
			name: 'continueCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'agreeAndContinueCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'declineCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'privacyPolicyCta',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'privacyPolicyLink',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'functionalConsentContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'advertisingConsentContent',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
