import { HiOutlineFilm } from '@react-icons/all-files/hi/HiOutlineFilm';
import { defineType } from '@sanity/types';

import { VideoType } from '../Video';

export type VideoKeyPointsType = {
	_type: 'VideoKeyPoints';
	initialExpanded: boolean;
	pointsPosition?: 'above_video' | 'below_video';
	video: VideoType;
	points: string[];
};

export default defineType({
	name: 'VideoKeyPoints',
	type: 'object',
	icon: HiOutlineFilm,
	fields: [
		{
			name: 'initialExpanded',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'pointsPosition',
			type: 'string',
			validation: (r) => r.required(),
			initialValue: 'above_video',
			options: {
				list: [
					{ title: 'Above video', value: 'above_video' },
					{ title: 'Below video', value: 'below_video' },
				],
			},
		},
		{
			name: 'video',
			type: 'reference',
			to: [{ type: 'Video' }],
		},
		{
			name: 'points',
			type: 'array',
			of: [{ type: 'string' }],
		},
	],
	preview: {
		select: {
			points: 'points',
		},
		prepare(selection) {
			const { points } = selection as Pick<VideoKeyPointsType, 'points'>;
			return {
				title: `VideoKeyPoints - ${points.length} points`,
			};
		},
	},
});
