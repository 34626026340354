import { defineType } from '@sanity/types';

export type ProgramActivityFilterExactActivityType = {
	_type: 'ProgramActivityFilterExactActivity';
	activity: { id: string };
};

export default defineType({
	name: 'ProgramActivityFilterExactActivity',
	type: 'object',
	fields: [
		{
			name: 'activity',
			type: 'reference',
			to: [{ type: 'ProgramActivity' }],
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'activity.title',
		},
	},
});
