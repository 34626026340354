import { FaRobot } from '@react-icons/all-files/fa6/FaRobot';
import { defineType } from '@sanity/types';

import { ksuidIdField, validateSnakeCase } from './_utils';
import { BlockContentWrapperType } from './block/BlockContentWrapper';

export type ItoInnerCriticModeType = {
	_type: 'ItoInnerCriticMode';
	id: string;
	textHumanloopPromptPath: string;
	textHumanloopPromptVersion?: string;
	textInitialQuestionTitle: string;
	textInitialQuestionDescription: string;
	textQuestionCount: number;
	visualizeHumanloopPromptPath: string;
	visualizeHumanloopPromptVersion?: string;
	visualizeReplicateModelIdentifier: string;
	visualizeQuestions?: {
		key: string;
		title: string;
		description?: string;
	}[];
	visualizeSummaryContent: BlockContentWrapperType;
};

export default defineType({
	name: 'ItoInnerCriticMode',
	type: 'document',
	icon: FaRobot,
	fields: [
		ksuidIdField('inncritmode', true),
		{
			name: 'textHumanloopPromptPath',
			type: 'string',
			description: 'The path for the Humanloop prompt',
			validation: (r) => r.required(),
		},
		{
			name: 'textHumanloopPromptVersion',
			type: 'string',
			description: 'The version of the the Humanloop prompt',
		},
		{
			name: 'textInitialQuestionTitle',
			type: 'string',
			description: 'The title of the initial question',
			validation: (r) => r.required(),
		},
		{
			name: 'textInitialQuestionDescription',
			type: 'string',
			description: 'The description of the initial question',
			validation: (r) => r.required(),
		},
		{
			name: 'textQuestionCount',
			type: 'number',
			description:
				'The number of questions to ask the user (including the initial question)',
			validation: (r) => r.required().min(1),
		},
		{
			name: 'visualizeHumanloopPromptPath',
			type: 'string',
			description: 'The path for the Humanloop prompt',
			validation: (r) => r.required(),
		},
		{
			name: 'visualizeHumanloopPromptVersion',
			type: 'string',
			description: 'The version of the the Humanloop prompt',
		},
		{
			name: 'visualizeReplicateModelIdentifier',
			type: 'string',
			description: 'The identifier for the Replicate model',
			validation: (r) => r.required(),
		},
		{
			name: 'visualizeQuestions',
			type: 'array',
			description: 'Questions to ask the user for the image generation',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'key',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required(),
						},
						{
							name: 'title',
							type: 'text',
							validation: (r) =>
								r.custom<string>((title) => {
									if (!title) return 'Title is required';
									if (title && title.split('\n').length > 1) {
										return 'Title must be a single line of text';
									}
									return true;
								}),
						},
						{
							name: 'description',
							type: 'text',
							validation: (r) =>
								r.custom<string>((text) => {
									if (text && text.split('\n').length > 1) {
										return 'Description must be a single line of text';
									}
									return true;
								}),
						},
					],
					preview: {
						select: {
							title: 'title',
							subtitle: 'key',
						},
					},
				},
			],
		},
		{
			name: 'visualizeSummaryContent',
			type: 'reference',
			validation: (r) => r.required(),
			to: [{ type: 'BlockContentWrapper' }],
		},
	],
	preview: {
		select: {
			visualizeReplicateModelIdentifier: 'visualizeReplicateModelIdentifier',
			textHumanloopPromptPath: 'textHumanloopPromptPath',
			subtitle: 'id',
		},
		prepare: ({
			visualizeReplicateModelIdentifier,
			textHumanloopPromptPath,
			subtitle,
		}) => ({
			title: `${textHumanloopPromptPath} - ${visualizeReplicateModelIdentifier}`,
			subtitle,
		}),
	},
});
