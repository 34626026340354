import { HiMinus } from '@react-icons/all-files/hi/HiMinus';
import { defineType } from '@sanity/types';

// aka LineBreak
export type HrType = {
	_type: 'Hr';
	style: 'default';
};

export default defineType({
	name: 'Hr',
	type: 'object',
	icon: HiMinus,
	fields: [
		{
			name: 'style',
			type: 'string',
			options: {
				list: ['default'],
			},
			initialValue: 'default',
		},
	],
	preview: {
		prepare: () => ({
			title: 'Line Break (Hr)',
		}),
	},
});
