import { HiInformationCircle } from '@react-icons/all-files/hi/HiInformationCircle';
import { defineType } from '@sanity/types';

import { SQScorePillar } from '../SQScorePillar';
import { InlineContentWrapperType } from './InlineContentWrapper';

export type ContentCardListType = {
	_type: 'ContentCardList';
	pillar?: SQScorePillar;
	cards: {
		content: InlineContentWrapperType;
	}[];
};

export default defineType({
	name: 'ContentCardList',
	type: 'object',
	icon: HiInformationCircle,
	fields: [
		{
			name: 'pillar',
			type: 'string',
			options: {
				list: Object.values(SQScorePillar).map((value) => ({
					title:
						value.replace(/_/g, ' ').substring(0, 1).toUpperCase() +
						value.replace(/_/g, ' ').substring(1),
					value,
				})),
			},
		},
		{
			name: 'cards',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'content',
							type: 'reference',
							to: [{ type: 'InlineContentWrapper' }],
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
	],
	preview: {
		select: {
			pillar: 'pillar',
			cards: 'cards',
		},
		prepare: ({ pillar, cards }) => {
			return {
				title: pillar
					? `${pillar.replace(/_/g, ' ')} - ${cards.length} content cards`
					: `${cards.length} content cards`,
			};
		},
	},
});
