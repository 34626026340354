import { defineType } from '@sanity/types';

import { validateSnakeCase } from '../_utils';
import { InlineContentWrapperType } from '../block/InlineContentWrapper';

export type FragmentOneActivityADayType = {
	_type: 'FragmentOneActivityADay';
	getAnotherActivityContent: {
		title: string;
		description: InlineContentWrapperType;
	};
	getAnotherActivityTitle: string;
	completedActivityContent: {
		title: string;
		description: InlineContentWrapperType;
	};
	question: {
		title: string;
		answers: {
			key: string;
			title: string;
		}[];
	};
};

export default defineType({
	name: 'FragmentOneActivityADay',
	type: 'document',
	fields: [
		{
			name: 'getAnotherActivityContent',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'description',
					type: 'reference',
					to: [{ type: 'InlineContentWrapper' }],
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'getAnotherActivityTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'completedActivityContent',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'description',
					type: 'reference',
					to: [{ type: 'InlineContentWrapper' }],
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'question',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'answers',
					type: 'array',
					validation: (r) =>
						r
							.custom<FragmentOneActivityADayType['question']['answers']>(
								(val) => {
									const keys = val.map((v) => v.key);
									if (new Set(keys).size !== keys.length) {
										return 'Keys must be unique';
									}
									return true;
								},
							)
							.required(),
					of: [
						{
							type: 'object',
							validation: (r) => r.required(),
							fields: [
								{
									name: 'key',
									type: 'string',
									validation: (r) => validateSnakeCase(r).required(),
								},
								{
									name: 'title',
									type: 'string',
									validation: (r) => r.required(),
								},
							],
						},
					],
				},
			],
		},
	],
});
