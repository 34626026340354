import { defineField, defineType } from '@sanity/types';

import { Document } from '../_types';
import { BlockContentWrapperType } from '../block/BlockContentWrapper';

type ModalFragment = {
	title: string;
	content: BlockContentWrapperType;
	ctaText: string;
	textActionCta?: string;
};

export type FragmentExploreType = Document & {
	_type: 'FragmentExplore';
	lockedModal: ModalFragment;
};

const modalContent = [
	defineField({
		name: 'title',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'content',
		type: 'reference',
		to: [{ type: 'BlockContentWrapper' }],
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'ctaText',
		type: 'string',
		validation: (r) => r.required(),
	}),
	defineField({
		name: 'textActionCta',
		type: 'string',
	}),
];

export default defineType({
	name: 'FragmentExplore',
	type: 'document',
	fields: [
		{
			name: 'lockedModal',
			type: 'object',
			validation: (r) => r.required(),
			fields: modalContent,
		},
	],
});
