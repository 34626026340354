import { HiOutlineFilm } from '@react-icons/all-files/hi/HiOutlineFilm';
import { defineType } from '@sanity/types';

import { Document, Image } from './_types';

export type VideoType = Document & {
	_type: 'Video';
	name: string;
	cloudflareVideoId: string;
	mp4VideoUrl?: string;
	poster?: Image;
	autoplay?: boolean;
	aspectRatio?: number;
	maxHeightPercentage?: number;
	useNativePlayerOnMobile?: boolean;
};

export default defineType({
	name: 'Video',
	type: 'document',
	icon: HiOutlineFilm,
	fields: [
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'cloudflareVideoId',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'mp4VideoUrl',
			type: 'url',
		},
		{
			name: 'poster',
			type: 'image',
		},
		{
			name: 'autoplay',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'aspectRatio',
			description:
				'No need to set if it is a landscape 16:9 video. Otherwise, it is recommended.',
			type: 'number',
			validation: (r) => r.min(0.01),
		},
		{
			name: 'maxHeightPercentage',
			description:
				"Used if we don't want a portrait video to go below the fold",
			type: 'number',
			validation: (r) => r.min(0.01).max(1),
		},
		{
			name: 'useNativePlayerOnMobile',
			description:
				"If video doesn't contain subtitles, then we can use the native player instead",
			type: 'boolean',
		},
	],
	preview: {
		select: {
			title: 'name',
			subtitle: 'cloudflareVideoId',
		},
	},
});
