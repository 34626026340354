import { HiOutlineChartSquareBar } from '@react-icons/all-files/hi/HiOutlineChartSquareBar';
import { defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { IconType } from '../Icon';
import { PollType } from '../Poll';
import { FlowBasePageType } from './baseTypes';

export type FlowPollQuestionAnswerStepType = FlowBasePageType & {
	_type: 'FlowPollQuestionAnswerStep';
	content?: BlockContentType;
	poll: PollType;
	pollHideResults: true;
	pollSelectionRequired: boolean;
	selectedPollContent: {
		pollOptionKey: string;
		icon: IconType;
		title: string;
		content: BlockContentType;
		ctaText: string;
	}[];
	contentAppend?: BlockContentType;
};

export default defineType({
	name: 'FlowPollQuestionAnswerStep',
	type: 'document',
	icon: HiOutlineChartSquareBar,
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'BlockContent',
		},
		{
			name: 'poll',
			type: 'reference',
			to: [{ type: 'Poll' }],
			validation: (r) => r.required(),
		},
		{
			name: 'pollHideResults',
			type: 'boolean',
			initialValue: true,
			readOnly: true,
		},
		{
			name: 'pollSelectionRequired',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'selectedPollContent',
			type: 'array',
			validation: (r) => r.min(1).required(),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'pollOptionKey',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'icon',
							type: 'Icon',
							validation: (r) => r.required(),
						},
						{
							name: 'title',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'content',
							type: 'BlockContent',
							validation: (r) => r.required(),
						},
						{
							name: 'ctaText',
							type: 'string',
							initialValue: 'Continue',
							validation: (r) => r.required(),
						},
					],
					preview: {
						select: {
							title: 'pollOptionKey',
							subtitle: 'title',
						},
					},
				},
			],
		},
		{
			name: 'contentAppend',
			type: 'BlockContent',
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
	preview: previewNamedBlockContent('title'),
});
