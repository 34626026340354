import { Document } from '../_types';
import { PollType } from '../Poll';
import { ProgramActivityFilterType } from '../program/_types';

// 👋 Making changes? All expressions should be kept fully up-to-date & in sync
// (merged in the same PR) for all expression engine implementations - see:
//
// - Go: expressionengine library/package
// - JS: expressionEvaluation.ts utility
//
// Please also try to maintain broadly the same test coverage/test cases.
//
// We try to stick strictly to this because expressions are widely used & edited
// by everyone in the CMS, so we don't want less-technical staff to have to
// worry about whether expressions will work correctly in different places.

const expressionObjectBooleanTypes = [
	'ExpressionAppHasMinimumVersion',
	'ExpressionArrayContainsString',
	'ExpressionArrayIsEmpty',
	'ExpressionArrayIsNotEmpty',
	'ExpressionConditionalBoolean',
	'ExpressionConstantBoolean',
	'ExpressionFeatureIsEnabled',
	'ExpressionLogicalAnd',
	'ExpressionLogicalNot',
	'ExpressionLogicalOr',
	'ExpressionPlatformHasCapability',
	'ExpressionStringIsEqual',
	'ExpressionStringIsNotEqual',
];

const expressionDocumentBooleanTypes = [
	'ExpressionProgramActivityCompleted',
	'ExpressionProgramActivityNotCompleted',
	'ExpressionReusableBoolean',
];

export type ExpressionAppHasMinimumVersionType = {
	_type: 'ExpressionAppHasMinimumVersion';
	minimumVersion: string;
};

export type ExpressionArrayContainsStringType = {
	_type: 'ExpressionArrayContainsString';
	arrayOperand: [ExpressionArrayType];
	stringOperand: [ExpressionStringType];
};

export type ExpressionArrayIsEmptyType = {
	_type: 'ExpressionArrayIsEmpty';
	operand: [ExpressionArrayType];
};

export type ExpressionArrayIsNotEmptyType = {
	_type: 'ExpressionArrayIsNotEmpty';
	operand: [ExpressionArrayType];
};

export type ExpressionConditionalArrayType = {
	_type: 'ExpressionConditionalArray';
	cases: {
		conditionOperand: [ExpressionBooleanType];
		valueOperand: [ExpressionArrayType];
	}[];
	defaultValueOperand: [ExpressionArrayType];
};

export type ExpressionConditionalBooleanType = {
	_type: 'ExpressionConditionalBoolean';
	cases: {
		conditionOperand: [ExpressionBooleanType];
		valueOperand: [ExpressionBooleanType];
	}[];
	defaultValueOperand: [ExpressionBooleanType];
};

export type ExpressionConstantStringType = {
	_type: 'ExpressionConstantString';
	value: string;
};

export type ExpressionConstantArrayType = {
	_type: 'ExpressionConstantArray';
	value: unknown[];
};

export type ExpressionConstantBooleanType = {
	_type: 'ExpressionConstantBoolean';
	value: boolean;
};

export type ExpressionConditionalStringType = {
	_type: 'ExpressionConditionalString';
	cases: {
		conditionOperand: [ExpressionBooleanType];
		valueOperand: [ExpressionStringType];
	}[];
	defaultValueOperand: [ExpressionStringType];
};

export type ExpressionContextStringType = {
	_type: 'ExpressionContextString';
	contextPathOperand: [ExpressionStringType];
};

export type ExpressionFeatureIsEnabledType = {
	_type: 'ExpressionFeatureIsEnabled';
	featureKeyOperand: [ExpressionStringType];
};

export type ExpressionFeatureValueStringType = {
	_type: 'ExpressionFeatureValueString';
	featureKeyOperand: [ExpressionStringType];
};

export type ExpressionLogicalAndType = {
	_type: 'ExpressionLogicalAnd';
	operands: ExpressionBooleanType[];
};

export type ExpressionLogicalNotType = {
	_type: 'ExpressionLogicalNot';
	operand: [ExpressionBooleanType];
};

export type ExpressionLogicalOrType = {
	_type: 'ExpressionLogicalOr';
	operands: ExpressionBooleanType[];
};

export type ExpressionPlatformHasCapabilityType = {
	_type: 'ExpressionPlatformHasCapability';
	operand: 'notifications';
};

export type ExpressionPollAnswerKeysType = Document & {
	_type: 'ExpressionPollAnswerKeys';
	poll: Pick<PollType, 'id' | 'name'>;
};

export type ExpressionProgramActivityCompletedType = Document & {
	_type: 'ExpressionProgramActivityCompleted';
	filters?: ProgramActivityFilterType[];
	minCount: number;
};

export type ExpressionProgramActivityNotCompletedType = Document & {
	_type: 'ExpressionProgramActivityNotCompleted';
	filters?: ProgramActivityFilterType[];
	minCount: number;
};

export type ExpressionReusableArrayType = Document & {
	_type: 'ExpressionReusableArray';
	operand: [ExpressionArrayType];
};

export type ExpressionReusableBooleanType = Document & {
	_type: 'ExpressionReusableBoolean';
	operand: [ExpressionBooleanType];
};

export type ExpressionReusableStringType = Document & {
	_type: 'ExpressionReusableString';
	operand: [ExpressionStringType];
};

export type ExpressionStringIsEqualType = {
	_type: 'ExpressionStringIsEqual';
	operands: [ExpressionStringType, ExpressionStringType];
};

export type ExpressionStringIsNotEqualType = {
	_type: 'ExpressionStringIsNotEqual';
	operands: [ExpressionStringType, ExpressionStringType];
};

export type ExpressionBooleanType =
	| ExpressionAppHasMinimumVersionType
	| ExpressionArrayContainsStringType
	| ExpressionArrayIsEmptyType
	| ExpressionArrayIsNotEmptyType
	| ExpressionConditionalBooleanType
	| ExpressionConstantBooleanType
	| ExpressionFeatureIsEnabledType
	| ExpressionLogicalAndType
	| ExpressionLogicalNotType
	| ExpressionLogicalOrType
	| ExpressionPlatformHasCapabilityType
	| ExpressionProgramActivityCompletedType
	| ExpressionProgramActivityNotCompletedType
	| ExpressionReusableBooleanType
	| ExpressionStringIsEqualType
	| ExpressionStringIsNotEqualType;

const expressionObjectStringTypes = [
	'ExpressionConditionalString',
	'ExpressionConstantString',
	'ExpressionContextString',
	'ExpressionFeatureValueString',
];

const expressionDocumentStringTypes = ['ExpressionReusableString'];

export type ExpressionStringType =
	| ExpressionConditionalStringType
	| ExpressionConstantStringType
	| ExpressionContextStringType
	| ExpressionFeatureValueStringType
	| ExpressionReusableStringType;

const expressionObjectArrayTypes = [
	'ExpressionConditionalArray',
	'ExpressionConstantArray',
];

const expressionDocumentArrayTypes = [
	'ExpressionPollAnswerKeys',
	'ExpressionReusableArray',
];

export type ExpressionArrayType =
	| ExpressionConditionalArrayType
	| ExpressionConstantArrayType
	| ExpressionPollAnswerKeysType
	| ExpressionReusableArrayType;

export type ExpressionType =
	| ExpressionBooleanType
	| ExpressionStringType
	| ExpressionArrayType;

export const arrayOfTypesBoolean = [
	...expressionObjectBooleanTypes.map((type) => ({ type })),
	{
		type: 'reference',
		to: expressionDocumentBooleanTypes.map((type) => ({ type })),
	},
];

export const arrayOfTypesString = [
	...expressionObjectStringTypes.map((type) => ({ type })),
	{
		type: 'reference',
		to: expressionDocumentStringTypes.map((type) => ({ type })),
	},
];

export const arrayOfTypesArray = [
	...expressionObjectArrayTypes.map((type) => ({ type })),
	{
		type: 'reference',
		to: expressionDocumentArrayTypes.map((type) => ({ type })),
	},
];
