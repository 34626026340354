import {
	ConditionalPropertyCallbackContext,
	defineField,
	defineType,
	ValidationContext,
} from '@sanity/types';

import { File } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { FlowBaseStepType } from './baseTypes';

export type FlowExerciseRESupportStepType = FlowBaseStepType & {
	_type: 'FlowExerciseRESupportStep';
	// will be deprecated in the nearest future in favour of just using flows for everything
	pages: {
		title: InlineContentType;
		content: BlockContentType;
		lottieFile: File;
		durationSeconds?: number;
		startCtaText?: string;
		resetCtaText?: string;
		finishEarlyCtaText?: string;
	}[];
	lastPageCompleteExercise: PageFieldType;
	lastPageFinishEarly: PageFieldType;
	helperTips: PageFieldType;
};

type PageFieldType = {
	title: InlineContentType;
	content: BlockContentType;
};

const pageFields = [
	defineField({
		name: 'title',
		type: 'InlineContent',
		validation: (r) => r.required().length(1),
	}),
	defineField({
		name: 'content',
		type: 'BlockContent',
		validation: (r) => r.required(),
	}),
];

const hideCta = ({ parent }: ConditionalPropertyCallbackContext) => {
	const durationSeconds = parent?.durationSeconds;
	return !durationSeconds;
};

export default defineType({
	name: 'FlowExerciseRESupportStep',
	type: 'document',
	fields: [
		{
			name: 'icon',
			type: 'Icon',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'pages',
			type: 'array',
			deprecated: {
				reason: 'Will be implemented as a regular flow in the nearest future.',
			},
			validation: (r) => r.required(),
			of: [
				{
					type: 'object',
					validation: (r) => r.required(),
					fields: [
						{
							name: 'title',
							type: 'InlineContent',
							validation: (r) => r.required().length(1),
						},
						{
							name: 'content',
							type: 'BlockContent',
							validation: (r) => r.required(),
						},
						{
							name: 'lottieFile',
							type: 'file',
							options: {
								accept: 'application/json',
							},
						},
						{
							name: 'durationSeconds',
							type: 'number',
							validation: (r) => r.integer().positive(),
						},
						{
							name: 'startCtaText',
							type: 'string',
							hidden: hideCta,
							validation: (r) => r.custom(validateCta),
						},
						{
							name: 'resetCtaText',
							type: 'string',
							hidden: hideCta,
							validation: (r) => r.custom(validateCta),
						},
						{
							name: 'finishEarlyCtaText',
							type: 'string',
							hidden: hideCta,
							validation: (r) => r.custom(validateCta),
						},
					],
					preview: previewBlockContent(),
				},
			],
		},
		{
			name: 'lastPageCompleteExercise',
			type: 'object',
			validation: (r) => r.required(),
			fields: pageFields,
		},
		{
			name: 'lastPageFinishEarly',
			type: 'object',
			validation: (r) => r.required(),
			fields: pageFields,
		},
		{
			name: 'helperTips',
			type: 'object',
			validation: (r) => r.required(),
			fields: pageFields,
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Complete',
			validation: (r) => r.required(),
		},
		{
			name: 'footerContent',
			type: 'BlockContent',
		},
	],
});

const validateCta = (value: string, context: ValidationContext) => {
	const parent = context.parent as FlowExerciseRESupportStepType['pages'][0];
	if (!value && parent.durationSeconds) {
		return 'Required';
	}
	return true;
};
