import { defineType } from '@sanity/types';

import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { CommunityExpertType } from '../CommunityExpert';

export type FragmentCommunityExpertQuotesType = {
	_type: 'FragmentCommunityExpertQuotes';
	expertQuoteText: string;
	learnMoreCtaText: string;
	expertQuotes: {
		discourseTopicId: string;
		summary: InlineContentType;
		content?: BlockContentType;
		expert: CommunityExpertType;
	}[];
};

export default defineType({
	name: 'FragmentCommunityExpertQuotes',
	type: 'document',
	fields: [
		{
			name: 'expertQuoteText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'learnMoreCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'expertQuotes',
			type: 'array',
			validation: (r) =>
				r.custom<FragmentCommunityExpertQuotesType['expertQuotes']>((value) => {
					const discourseTopicIds = new Set<string>();
					for (const expertQuote of value) {
						if (discourseTopicIds.has(expertQuote.discourseTopicId)) {
							return 'Discourse Topic ID must be unique';
						}
						discourseTopicIds.add(expertQuote.discourseTopicId);
					}
					return true;
				}),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'discourseTopicId',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'expert',
							type: 'reference',
							to: [{ type: 'CommunityExpert' }],
							validation: (r) => r.required(),
						},
						{
							name: 'summary',
							type: 'InlineContent',
							validation: (r) => r.required(),
						},
						{
							name: 'content',
							type: 'BlockContent',
						},
					],
					preview: {
						select: {
							title: 'discourseTopicId',
							subtitle: 'summary',
							media: 'expert.image',
						},
					},
				},
			],
		},
	],
});
