import { HiOutlineVolumeUp } from '@react-icons/all-files/hi/HiOutlineVolumeUp';
import { defineType } from '@sanity/types';

import { Image } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { AudioType } from '../Audio';
import { FlowBaseStepType } from './baseTypes';

export type FlowAudioPlayerStepType = FlowBaseStepType & {
	_type: 'FlowAudioPlayerStep';
	id: string;
	audioName: string;
	audio: AudioType;
	playerImage: Image;
};

export default defineType({
	name: 'FlowAudioPlayerStep',
	type: 'document',
	icon: HiOutlineVolumeUp,
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'audio',
			type: 'reference',
			to: [{ type: 'Audio' }],
			validation: (r) => r.required(),
		},
		{
			name: 'audioName',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'playerImage',
			description: 'Image to display on the audio player screen',
			type: 'image',
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('exerciseName', 'audioName'),
});
