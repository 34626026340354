import { defineType } from '@sanity/types';

import { arrayOfTypesString, ExpressionFeatureValueStringType } from './_types';
import { renderExpression } from './_utils';

export default defineType({
	name: 'ExpressionFeatureValueString',
	title: 'Feature Value Text',
	type: 'object',
	fields: [
		{
			name: 'featureKeyOperand',
			type: 'array',
			validation: (r) => r.required().length(1),
			of: arrayOfTypesString,
		},
	],
	preview: {
		select: {
			_type: '_type',
			featureKeyOperand: 'featureKeyOperand',
		},
		prepare: (expression: ExpressionFeatureValueStringType) => ({
			title: renderExpression(expression),
		}),
	},
});
