import { HiOutlineSparkles } from '@react-icons/all-files/hi/HiOutlineSparkles';
import { defineType } from '@sanity/types';

import { Document, File } from './_types';

export type AnimationType = Document & {
	_type: 'Animation';
	name: string;
	autoPlay: boolean;
	loop: boolean;
	lottieFile: File;
	accompanyingAudio?: File;
};

export default defineType({
	name: 'Animation',
	type: 'document',
	icon: HiOutlineSparkles,
	fields: [
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'autoPlay',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'loop',
			type: 'boolean',
			initialValue: false,
		},
		{
			name: 'lottieFile',
			type: 'file',
			validation: (r) => r.required(),
			options: {
				accept: 'application/json',
			},
		},
		{
			name: 'accompanyingAudio',
			type: 'file',
			options: {
				accept: 'audio/mpeg',
			},
		},
	],
	preview: {
		select: {
			title: 'name',
		},
	},
});
