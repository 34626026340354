import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { InlineContentType } from '../block/InlineContent';

export type FragmentAuthMobileIntroScreenType = Document & {
	_type: 'FragmentAuthMobileIntroScreen';
	title: InlineContentType;
	subtitle: InlineContentType;
	loginCtaText: string;
	signupCtaText: string;
};

export default defineType({
	name: 'FragmentAuthMobileIntroScreen',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'subtitle',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'loginCtaText',
			type: 'string',
			title: 'Login CTA Text',
			validation: (r) => r.required(),
		},
		{
			name: 'signupCtaText',
			type: 'string',
			title: 'Signup CTA Text',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
