import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewNamedBlockContent } from '../_utils';
import { InlineContentType } from '../block/InlineContent';

export type FragmentUserDeletionType = Document & {
	_type: 'FragmentUserDeletion';
	confirmationTitle: string;
	confirmationMessage: InlineContentType;
	confirmationCtaText: string;
	contactSupportCtaText: string;
	cancelCtaText: string;
};

export default defineType({
	name: 'FragmentUserDeletion',
	type: 'document',
	fields: [
		{
			name: 'confirmationTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'confirmationMessage',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'confirmationCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'contactSupportCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'cancelCtaText',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('title', 'confirmationMessage'),
});
