import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { BlockContentType } from '../block/_types';

export type FragmentAppNudgeType = Document & {
	_type: 'FragmentAppNudge';
	content: BlockContentType;
	ctaText: string;
	playStoreUrl: string;
	appStoreUrl: string;
};

export default defineType({
	name: 'FragmentAppNudge',
	type: 'document',
	fields: [
		{
			name: 'content',
			type: 'BlockContent',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'playStoreUrl',
			type: 'url',
			validation: (r) => r.required(),
		},
		{
			name: 'appStoreUrl',
			type: 'url',
			validation: (r) => r.required(),
		},
	],
	preview: previewBlockContent(),
});
